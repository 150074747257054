/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:819c696c-c2b8-4b4b-b31b-a21d3ae4dc9b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_RyDtGhFos",
    "aws_user_pools_web_client_id": "67eo2n3uqhmf3ruoc6i4vtsinl",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://4625tviwgzbhnp2vj5pfxvmv3i.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-x3nrdqzla5fpjjpgehcgrztsfq",
    "aws_user_files_s3_bucket": "harunnaapp7dd9608993764847afd666d65dc204a911003-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
