import React, { useState, useEffect } from "react";

import "./Admin-FormHouse.css";
import { API, Storage } from "aws-amplify";

import { useForm } from "react-hook-form";

import { listHouseTypes, listStatuss } from "../graphql/queries";
import { createHouse as createHouseMutation, updateHouse as updateHouseMutation } from "../graphql/mutations";
import { allDataFromReference } from "../graphql/custom-queries";

import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Figure from "react-bootstrap/Figure";
import Container from "react-bootstrap/Container";
import uuid from "react-uuid";
import moment from "moment";

import bsCustomFileInput from "bs-custom-file-input";

import ListImages from "../commons/ListImages/ListImages";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faGem, faEdit, faSave } from "@fortawesome/free-regular-svg-icons";
import { faEye, faEyeSlash, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import ProgressUploadFiles from "../commons/ProgressUploadFiles/ProgressUploadFiles";

import "react-image-gallery/styles/css/image-gallery.css";

import imageCompression from "browser-image-compression";

const TYPE_HOUSE = "HOUSE";

const optionsCompressionImage = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1024,
  useWebWorker: true,
};

const AdminFormHouse = (props) => {
  const initialFormState = {
    id: null,
    title: "",
    surface: "",
    address: "",
    showAddress: false,
    housingEstate: "",
    price: "",
    description: "",
    highlighted: false,
    media: [],
    radioSold: false,
    radioRented: false,
    radioReserved: false,
    radioRent: false,
    radioSale: false,
    radioRentSaleOrRent: false,
    radioAvailable: false,
    city: "",
    bedrooms: "",
    bathrooms: "",
    zone: "",
    floor: "",
    door: "",
    block: "",
    lift: false,
    parking: false,
    pool: false,
    yard: false,
    storageroom: false,
    builtClosets: false,
    airConditioning: false,
    terrace: false,
    balcony: false,
    individualHeating: false,
    centralHeating: false,
    heatingNotAvailable: false,
    typeHouse: "",
    statusHouse: "",
    highlightedText: "",
    builtYear: "",
    builtArea: "",
    usefulSurface: "",
    surfacePlot: "",
    northOrientation: false,
    southOrientation: false,
    eastOrientation: false,
    westOrientation: false,
    energyCertification: "",
  };

  const [formState, setFormState] = useState(initialFormState);
  const [disabledSold, setDisabledSold] = useState(false);
  const [disabledRented, setDisabledRented] = useState(false);
  const [disabledReserved, setDisabledReserved] = useState(false);
  const [disabledAvailable, setDisabledAvailable] = useState(false);
  const [houseTypes, setHouseTypes] = useState([]);
  const [houseStatus, setHouseStatus] = useState([]);
  const [uuidHouse, setUUIDHouse] = useState(uuid());
  const [mediaRemove, setMediaRemove] = useState([]);
  const [highlightedImage, setHighlightedImage] = useState();
  const { register, errors, handleSubmit } = useForm();
  const [uploadHouse, setUploadHouse] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [flatSelected, setFlatSelected] = useState(false);

  useEffect(() => {
    fetchHouseType();
    fetchHouseStatus();
    bsCustomFileInput.init();
  }, []);

  useEffect(() => {
    loadHouse();
  }, [props.house]);

  useEffect(() => {
    if (isUpdated) {
      let type = reloadHouseType();
      let status = reloadHouseStatus();
      let copyState = { ...formState };
      copyState.typeHouse = type;
      copyState.statusHouse = status;
      setFormState(copyState);
    }
  }, [isUpdated, houseTypes, houseStatus]);

  function reloadHouseType() {
    let toReturn = 0;
    houseTypes.map((item) => {
      if (formState.houseTypeOrigi === item.type) {
        toReturn = item.uuid;
      }
    });
    return toReturn;
  }

  function reloadHouseStatus() {
    let toReturn = 0;
    houseStatus.map((item) => {
      if (formState.houseStatusOrigi === item.status) {
        toReturn = item.uuid;
      }
    });
    return toReturn;
  }

  async function loadHouse() {
    if (props.house !== undefined && props.house !== "") {
      const apiData = await API.graphql({
        query: allDataFromReference,
        variables: { reference: props.house },
      });
      if (apiData.data.houseByReference.items.length > 0) {
        fillInitialState(apiData.data.houseByReference.items[0]);
      }
    }
  }

  async function fillInitialState(data) {
    // let loadedState = { ...formState };
    let loadedState = {
      id: data.id,
      bathrooms: data.bathrooms,
      bedrooms: data.bedrooms,
      city: data.city,
      description: data.description,
      address: data.address,
      housingEstate: data.housingEstate,
      showAddress: data.showAddress,
      highlighted: data.highlighted,
      highlightedText: data.highlightedText,
      houseStatusOrigi: data.houseStatus,
      houseTypeOrigi: data.houseType,
      statusHouse: 0,
      typeHouse: 0,
      price: buildCommas(data.price),
      reference: data.reference,
      radioRent: data.rent,
      radioRented: data.rented,
      radioReserved: data.reserved,
      radioRentSaleOrRent: data.rentSaleOrRent,
      radioSale: data.sale,
      radioSold: data.sold,
      radioAvailable: !data.sold && !data.sale && !data.rented,
      surface: data.surface,
      title: data.title,
      zone: data.zone,
      floor: data.floor,
      door: data.door,
      block: data.block,
      builtYear: data.builtYear,
      builtArea: data.builtArea,
      usefulSurface: data.usefulSurface,
      surfacePlot: data.surfacePlot,
      northOrientation: data.northOrientation,
      southOrientation: data.southOrientation,
      eastOrientation: data.eastOrientation,
      westOrientation: data.westOrientation,
      energyCertification: data.energyCertification,
      lift: data.lift,
      parking: data.parking,
      pool: data.pool,
      yard: data.yard,
      storageroom: data.storageRoom,
      builtClosets: data.builtClosets,
      airConditioning: data.airConditioning,
      terrace: data.terrace,
      balcony: data.balcony,
      individualHeating: data.individualHeating,
      centralHeating: data.centralHeating,
      heatingNotAvailable: data.heatingNotAvailable,
      mediaOrigi: data.media,
    };
    setHighlightedImage({
      picture: await loadImage(data.mediaHighlighted),
      id: data.mediaHighlighted,
      isUpdated: true,
    });
    loadImages(loadedState, data.media);
  }

  async function loadImage(imageId) {
    let url = await Storage.get(imageId, {
      level: "public",
    });
    return url;
  }

  async function loadImages(loadedState, images) {
    let urlImages = [];
    let allResolved = Promise.all(
      await images.map(async (image) => {
        let url = await Storage.get(image, {
          level: "public",
        });
        urlImages.push({
          id: image,
          picture: url,
          displayModal: false,
          highlightedActive: loadedState.highlighted,
          isUpdated: true,
        });
      }),
    );

    allResolved.then((then) => {
      loadedState.media = urlImages;
      setFormState(loadedState);
      setIsUpdated(true);
    });
  }

  async function onChargeImages(e) {
    if (!e.target.files[0]) return;
    let files = Object.values(e.target.files);
    let copyState = { ...formState };
    files.map((current) => {
      let nIdPic = uuidHouse + "-" + current.name;
      let found = copyState.media.some((pic) => pic.id === nIdPic);
      if (!found) {
        copyState.media.push({
          id: nIdPic,
          picture: current,
          displayModal: false,
          highlightedActive: formState.highlighted,
        });
      } else {
        props.addNotifications({
          id: uuid(),
          show: true,
          delay: 6000,
          typeName: "error",
          message: "Ya existe una imagen con ese nombre",
        });
      }
    });
    setFormState(copyState);
  }
  function imageHighlighted(imageId) {
    formState.media.map((item) => {
      if (item.id === imageId) setHighlightedImage(item);
    });
  }

  function removeMediaElements() {
    let copyState = { ...formState };
    let media = [];
    copyState.media.map((item) => {
      let remove = false;
      mediaRemove.map((currentMedia) => {
        if (currentMedia === item.id) remove = true;
        if (highlightedImage !== undefined && currentMedia === highlightedImage.id) setHighlightedImage();
      });
      if (!remove) media.push(item);
    });
    copyState.media = media;
    setFormState(copyState);
    setMediaRemove([]);
  }

  function saleOrRent(key, copyFormState) {
    copyFormState.radioSold = false;
    copyFormState.radioRented = false;
    copyFormState.radioReserved = false;
    copyFormState.radioAvailable = false;
    if (key === "radioRent") {
      setDisabledReserved(false);
      setDisabledSold(true);
      setDisabledAvailable(false);
      setDisabledRented(false);
      copyFormState.radioSale = false;
      copyFormState.radioRentSaleOrRent = false;
    } else if (key === "radioSale") {
      setDisabledReserved(false);
      setDisabledSold(false);
      setDisabledAvailable(false);
      setDisabledRented(true);
      copyFormState.radioRent = false;
      copyFormState.radioRentSaleOrRent = false;
    } else if (key === "radioRentSaleOrRent") {
      setDisabledReserved(false);
      setDisabledSold(false);
      setDisabledAvailable(false);
      setDisabledRented(true);
      copyFormState.radioRent = false;
      copyFormState.radioSale = false;
    }
    return copyFormState;
  }

  function radioButtonsControl(target) {
    const copyFormState = { ...formState };
    if (target.type === "radio") {
      if (target.id === "radioReserved") {
        copyFormState.radioSold = false;
        copyFormState.radioAvailable = false;
      } else if (target.id === "radioSold") {
        copyFormState.radioReserved = false;
        copyFormState.radioAvailable = false;
      } else if (target.id === "radioAvailable") {
        copyFormState.radioReserved = false;
        copyFormState.radioSold = false;
      } else if (
        target.id === "individualHeating" ||
        target.id === "centralHeating" ||
        target.id === "heatingNotAvailable"
      ) {
        copyFormState.individualHeating = false;
        copyFormState.centralHeating = false;
        copyFormState.heatingNotAvailable = false;
        return copyFormState;
      }

      return saleOrRent(target.id, copyFormState);
    }
    return copyFormState;
  }

  function onChange(e) {
    e.persist();
    let copyState = radioButtonsControl(e.target);
    let targetValue = getValueTarget(e.target);
    if (e.target.id === "highlighted") {
      copyState.media.map((item) => {
        item.highlightedActive = targetValue;
      });
      if (!targetValue) setHighlightedImage();
    }
    if (e.target.id === "typeHouse") {
      if (targetValue === "10") {
        setFlatSelected(true);
      } else {
        setFlatSelected(false);
      }
    }
    setFormState(() => ({
      ...copyState,
      [e.target.id]: targetValue,
    }));
  }

  function onChangeNumber(e) {
    e.persist();
    let value = getValueTarget(e.target);

    const numberRegExp = /^[0-9]+$|^$/;
    const number2DecimalsRegExp = /^[0-9]+\.[0-9]{1,2}$|^$/;
    const numberOnlyDotRegExp = /^[0-9]+\.$|^$/;

    let valueToUpdate = value;

    let updateState = false;

    if (
      e.target.id === "builtYear" ||
      e.target.id === "surface" ||
      e.target.id === "surfacePlot" ||
      e.target.id === "usefulSurface" ||
      e.target.id === "builtArea" ||
      e.target.id === "bedrooms" ||
      e.target.id === "bathrooms"
    ) {
      if (numberRegExp.test(value)) {
        updateState = true;
        valueToUpdate = value === "" || value === "0" ? value : Number(value);
      }
    } else if (e.target.id === "price") {
      if (numberRegExp.test(value)) {
        updateState = true;
        valueToUpdate = value === "" || value === "0" ? value : Number(value);
      } else if (number2DecimalsRegExp.test(value)) {
        updateState = true;
        valueToUpdate = value === "" || value === "0" ? value : Number(value);
      } else if (numberOnlyDotRegExp.test(value)) {
        updateState = true;
        valueToUpdate = value;
      }
    }

    if (updateState) {
      setFormState(() => ({
        ...formState,
        [e.target.id]: valueToUpdate,
      }));
    }
  }

  function addCommas(e) {
    e.persist();
    let nStr = e.target.value;
    if (nStr !== "") {
      setFormState(() => ({
        ...formState,
        [e.target.id]: buildCommas(nStr),
      }));
    }
  }

  function buildCommas(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2 + ",00";
  }

  function removeCommas(e) {
    e.persist();
    let value = delCommas(e.target.value);
    if (value !== "") {
      if (e.target && value) {
        setFormState(() => ({
          ...formState,
          [e.target.id]: value,
        }));
      }
    }
  }

  function delCommas(value) {
    let val = value.replaceAll(".", "").split(",");
    if (val.length > 0) {
      return val[0];
    }
    return "";
  }

  function getValueTarget(target) {
    if (target.type === "radio") {
      return target.translate;
    } else if (target.type === "checkbox") {
      return target.checked;
    } else {
      return target.value;
    }
  }

  async function fetchHouseType() {
    const apiData = await API.graphql({ query: listHouseTypes });
    setHouseTypes(apiData.data.listHouseTypes.items);
  }

  async function fetchHouseStatus() {
    const apiData = await API.graphql({ query: listStatuss });
    setHouseStatus(apiData.data.listStatuss.items);
  }

  async function createHouse() {
    setUploadHouse(true);
    let inputHouse = {
      bathrooms: formState.bathrooms,
      bedrooms: formState.bedrooms,
      city: formState.city,
      description: formState.description,
      address: formState.address,
      housingEstate: formState.housingEstate,
      showAddress: formState.showAddress,
      highlighted: formState.highlighted,
      highlightedText: formState.highlightedText,
      houseStatus: null,
      houseType: null,
      price: delCommas(formState.price),
      rent: formState.radioRent,
      rented: formState.radioRented,
      reserved: formState.radioReserved,
      rentSaleOrRent: formState.radioRentSaleOrRent,
      sale: formState.radioSale,
      sold: formState.radioSold,
      surface: formState.surface,
      title: formState.title,
      zone: formState.zone,
      floor: formState.floor,
      door: formState.door,
      block: formState.block,
      builtYear: formState.builtYear,
      builtArea: formState.builtArea,
      usefulSurface: formState.usefulSurface,
      surfacePlot: formState.surfacePlot,
      northOrientation: formState.northOrientation,
      southOrientation: formState.southOrientation,
      eastOrientation: formState.eastOrientation,
      westOrientation: formState.westOrientation,
      energyCertification: formState.energyCertification,
      lift: formState.lift,
      parking: formState.parking,
      pool: formState.pool,
      yard: formState.yard,
      storageRoom: formState.storageroom,
      builtClosets: formState.builtClosets,
      airConditioning: formState.airConditioning,
      terrace: formState.terrace,
      balcony: formState.balcony,
      individualHeating: formState.individualHeating,
      centralHeating: formState.centralHeating,
      heatingNotAvailable: formState.heatingNotAvailable,
      media: [],
    };

    let type = houseTypes.filter((item) => item.uuid === formState.typeHouse);
    let status = houseStatus.filter((item) => item.uuid === formState.statusHouse);

    inputHouse.houseType = type[0].type;
    inputHouse.houseStatus = status[0].status;

    if (isUpdated) {
      inputHouse.id = formState.id;
      inputHouse.reference = formState.reference;
      inputHouse.mediaHighlighted = highlightedImage ? highlightedImage.id : "";
      //Purge images
      let mediaRemove = [];
      formState.mediaOrigi.map((item) => {
        let result = formState.media.filter((val) => val.id === item);
        if (result.length === 0) {
          mediaRemove.push(item);
        } else {
          inputHouse.media.push(item);
        }
      });
      await removeImages(mediaRemove);
    } else {
      inputHouse.showAfterSoldOrRent = true;
      inputHouse.publicationDate = moment(new Date()).format("DD-MM-YYYY HH:mm:ss");
      inputHouse.reference = Math.random().toString(36).substr(2, 9).toUpperCase();
      inputHouse.type = TYPE_HOUSE;
    }

    await uploadImages(inputHouse);

    if (isUpdated) {
      let updated = await API.graphql({ query: updateHouseMutation, variables: { input: inputHouse } }).catch(
        (error) => {
          props.addNotifications({
            id: uuid(),
            show: true,
            delay: 6000,
            typeName: "error",
            message: "Error al crear la casa",
          });
        },
      );
      if (updated) {
        setUploadHouse(false);
        props.returnListHouses(!true);
      }
    } else {
      let newHouse = await API.graphql({ query: createHouseMutation, variables: { input: inputHouse } }).catch(
        (error) => {
          props.addNotifications({
            id: uuid(),
            show: true,
            delay: 6000,
            typeName: "error",
            message: "Error al crear la casa",
          });
        },
      );
      if (newHouse) {
        setUploadHouse(false);
        props.returnListHouses(!true);
      }
    }
  }

  async function uploadImages(inputHouse) {
    let copyState = { ...formState };
    return Promise.all(
      copyState.media.map(async (current) => {
        if (!current.isUpdated) {
          let isHighlightedImage = highlightedImage && current.id === highlightedImage.id;
          let id = current.picture.type.startsWith("video/") ? "video-" + current.id : current.id;
          let imageCompressed = current.picture.type.startsWith("video/")
            ? current.picture
            : await imageCompression(current.picture, optionsCompressionImage);
          await Storage.put(id, imageCompressed, {
            cacheControl: "",
            level: "public",
            contentType: current.picture.type,
            progressCallback(progress) {
              console.log("porcentaje: " + (progress.loaded * 100) / progress.total);
            },
          })
            .then((result) => {
              console.log(result);
              inputHouse.media.push(result.key);
              if (isHighlightedImage) inputHouse.mediaHighlighted = result.key;
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }),
    );
  }

  async function removeImages(images) {
    return Promise.all(
      images.map(async (current) => {
        Storage.remove(current).catch((err) => console.log(err));
      }),
    );
  }

  return (
    <Form onSubmit={handleSubmit(createHouse)}>
      {uploadHouse && <ProgressUploadFiles />}
      <Form.Row>
        <Form.Group as={Col} md="12">
          <Form.Label>Titulo</Form.Label>
          <Form.Control
            type="text"
            placeholder="Titulo"
            id="title"
            name="title"
            onChange={onChange}
            value={formState.title}
            ref={register({
              required: {
                value: true,
                message: "Titulo es requerido",
              },
              minLength: {
                value: 7,
                message: "Mínimo 7 carácteres",
              },
            })}
          />
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.title?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                {errors?.title?.message}
              </p>
            )}
          </span>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Descrición</Form.Label>
          <Form.Control
            id="description"
            name="description"
            as="textarea"
            placeholder="Descripcion"
            rows={3}
            onChange={onChange}
            value={formState.description}
            ref={register({
              required: {
                value: true,
                message: "Descripción es necesario",
              },
            })}
          />
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.description?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                {errors?.description?.message}
              </p>
            )}
          </span>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="5" sm="10" xs="10">
          <Form.Label>Dirección</Form.Label>
          <Form.Control
            type="text"
            placeholder="Dirección"
            id="address"
            name="address"
            onChange={onChange}
            value={formState.address}
            ref={register({
              required: {
                value: true,
                message: "La dirección es requerida",
              },
              minLength: {
                value: 7,
                message: "Mínimo 7 carácteres",
              },
            })}
          />
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.title?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.title?.message}
              </p>
            )}
          </span>
        </Form.Group>
        <Form.Group as={Col} md="1" sm="2" xs="2">
          <Form.Label as="label">
            {" "}
            {!formState.showAddress && <FontAwesomeIcon icon={faEyeSlash} color="black" />}
            {formState.showAddress && <FontAwesomeIcon icon={faEye} color="black" />}
          </Form.Label>
          <Form.Check
            id="showAddress"
            type="switch"
            label=""
            onChange={onChange}
            value={formState.showAddress}
            checked={formState.showAddress}
          />
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Label>Zona</Form.Label>
          <Form.Control
            type="text"
            placeholder="Zona"
            id="zone"
            name="zone"
            onChange={onChange}
            value={formState.zone}
            ref={register({
              required: {
                value: true,
                message: "Zona es necesario",
              },
            })}
          />
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.zone?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                {errors?.zone?.message}
              </p>
            )}
          </span>
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Label>Ciudad</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ciudad"
            id="city"
            name="city"
            onChange={onChange}
            value={formState.city}
            ref={register({
              required: {
                value: true,
                message: "Ciudad es necesario",
              },
            })}
          />
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.city?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.city?.message}
              </p>
            )}
          </span>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="3">
          <Form.Label>Urbanización</Form.Label>
          <Form.Control
            type="text"
            placeholder="Urbanización"
            id="housingEstate"
            name="housingEstate"
            onChange={onChange}
            value={formState.housingEstate}
          />
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Label>Tipo de casa</Form.Label>
          <Form.Control
            as="select"
            id="typeHouse"
            name="typeHouse"
            onChange={onChange}
            value={formState.typeHouse}
            ref={register({
              required: {
                value: true,
                message: "Selecciona el tipo de casa",
              },
              pattern: {
                value: /[1-9]+/g,
                message: "Selecciona el tipo de la casa",
              },
            })}
          >
            <option value="0" defaultValue>
              - Tipo -
            </option>
            {houseTypes.map((value) => (
              <option key={value.uuid} id={value.uuid} value={value.uuid}>
                {value.type}
              </option>
            ))}
          </Form.Control>
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.typeHouse?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.typeHouse?.message}
              </p>
            )}
          </span>
        </Form.Group>
        {flatSelected && (
          <Col md="6" className="inputs-flat">
            <Form.Group as={Col} md="4">
              <Form.Label>Planta</Form.Label>
              <InputGroup>
                <Form.Control
                  id="floor"
                  name="floor"
                  type="text"
                  placeholder="Planta"
                  onChange={onChange}
                  value={formState.floor}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Puerta</Form.Label>
              <InputGroup>
                <Form.Control
                  id="door"
                  name="door"
                  type="text"
                  placeholder="Puerta"
                  onChange={onChange}
                  value={formState.door}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Bloque</Form.Label>
              <InputGroup>
                <Form.Control
                  id="block"
                  name="block"
                  type="text"
                  placeholder="Bloque"
                  onChange={onChange}
                  value={formState.block}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="2">
          <Form.Label>Superficie</Form.Label>
          <InputGroup>
            <Form.Control
              id="surface"
              name="surface"
              type="text"
              placeholder="Superficie"
              aria-describedby="inputGroupPrepend"
              onChange={onChangeNumber}
              value={formState.surface}
              ref={register({
                required: {
                  value: true,
                  message: "El campo Superficie es necesario",
                },
                pattern: {
                  value: /[0-9]+/g,
                  message: "Solo son validos números",
                },
              })}
            />
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">m2</InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.surface?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.surface?.message}
              </p>
            )}
          </span>
        </Form.Group>
        <Form.Group as={Col} md="2">
          <Form.Label>Precio</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              id="price"
              name="price"
              placeholder="Precio"
              aria-describedby="inputGroupPrepend"
              onChange={onChangeNumber}
              onBlur={addCommas}
              onFocus={removeCommas}
              value={formState.price}
              ref={register({
                required: {
                  value: true,
                  message: "El campo Precio es necesario",
                },
                pattern: {
                  value: /[0-9]+/g,
                  message: "Solo son validos números",
                },
              })}
            />
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">€</InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.price?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.price?.message}
              </p>
            )}
          </span>
        </Form.Group>
        <Form.Group as={Col} md="2">
          <Form.Label>Habitaciones</Form.Label>
          <Form.Control
            id="bedrooms"
            name="bedrooms"
            type="text"
            placeholder="Habitaciones"
            onChange={onChangeNumber}
            value={formState.bedrooms}
            ref={register({
              required: {
                value: true,
                message: "El numero Habitaciones es necesario",
              },
              pattern: {
                value: /[0-9]+/g,
                message: "Solo son validos números",
              },
            })}
          />
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.bedrooms?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.bedrooms?.message}
              </p>
            )}
          </span>
        </Form.Group>
        <Form.Group as={Col} md="2">
          <Form.Label>Baños</Form.Label>
          <Form.Control
            id="bathrooms"
            name="bathrooms"
            type="text"
            placeholder="Baños"
            onChange={onChangeNumber}
            value={formState.bathrooms}
            ref={register({
              required: {
                value: true,
                message: "el numero de Baños es necesario",
              },
              pattern: {
                value: /[0-9]+/g,
                message: "Solo son validos números",
              },
            })}
          />
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.bathrooms?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.bathrooms?.message}
              </p>
            )}
          </span>
        </Form.Group>
        <Form.Group as={Col} md="2">
          <Form.Label>Estado</Form.Label>
          <Form.Control
            as="select"
            id="statusHouse"
            name="statusHouse"
            onChange={onChange}
            value={formState.statusHouse}
            ref={register({
              required: {
                value: true,
                message: "Selecciona el estado de la casa",
              },
              pattern: {
                value: /[1-9]+/g,
                message: "Selecciona el estado de la casa",
              },
            })}
          >
            <option value="0" defaultValue>
              - Estado -
            </option>
            {houseStatus.map((value) => (
              <option key={value.uuid} id={value.uuid} value={value.uuid}>
                {value.status}
              </option>
            ))}
          </Form.Control>
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.statusHouse?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.statusHouse?.message}
              </p>
            )}
          </span>
        </Form.Group>
        <Form.Group as={Col} md="2">
          <Form.Label>Certificación energética</Form.Label>
          <Form.Control
            as="select"
            id="energyCertification"
            name="energyCertification"
            onChange={onChange}
            value={formState.energyCertification}
            ref={register({
              required: {
                value: true,
                message: "Selecciona el certificado energético",
              },
              pattern: {
                value: /^(not-available|A|B|C|D|E|F|G|in-process|excent)$/g,
                message: "Selecciona el certificado energético",
              },
            })}
          >
            <option value="0" defaultValue>
              - Selecciona -
            </option>
            <option value="not-available">Aún no disponible</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
            <option value="E">E</option>
            <option value="F">F</option>
            <option value="G">G</option>
            <option value="in-process">En trámite</option>
            <option value="excent">Inmueble exento</option>
          </Form.Control>
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.energyCertification?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.energyCertification?.message}
              </p>
            )}
          </span>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="3" sm="6" xs="6" className="border-form-house border-right-0">
          <Form.Label as="label">Venta/Alquiler</Form.Label>
          <Form.Check
            type="radio"
            label="En Alquiler"
            name="radioRentSaleOrRent"
            id="radioRent"
            onChange={onChange}
            checked={formState.radioRent}
            value={formState.radioRent}
            ref={register({
              required: {
                value: true,
                message: "Seleciona Venta o Alquiler",
              },
            })}
          />
          <Form.Check
            type="radio"
            label="En Venta"
            name="radioRentSaleOrRent"
            id="radioSale"
            onChange={onChange}
            checked={formState.radioSale}
            value={formState.radioSale}
            ref={register({
              required: {
                value: true,
                message: "Seleciona Venta o Alquiler",
              },
            })}
          />
          <Form.Check
            type="radio"
            label="Alquiler con opción a compra"
            name="radioRentSaleOrRent"
            id="radioRentSaleOrRent"
            onChange={onChange}
            checked={formState.radioRentSaleOrRent}
            value={formState.radioRentSaleOrRent}
            ref={register({
              required: {
                value: true,
                message: "Seleciona una de las opciones",
              },
            })}
          />
          <span className="text-danger text-small d-block mb-2 span-error-app">
            {errors?.radioRentOrSale?.message && (
              <p>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.radioRentOrSale?.message}
              </p>
            )}
          </span>
        </Form.Group>
        <Form.Group as={Col} md="3" sm="6" xs="6" className="border-form-house border-left-0">
          <Form.Label as="label">Vendido/Reservado</Form.Label>
          <Form.Check
            type="radio"
            label="Vendido"
            name="radioSoldOrReserved"
            id="radioSold"
            disabled={disabledSold}
            checked={formState.radioSold}
            onChange={onChange}
            value={formState.radioSold}
          />
          <Form.Check
            type="radio"
            label="Alquilado"
            name="radioSoldOrReserved"
            id="radioRented"
            disabled={disabledRented}
            checked={formState.radioRented}
            onChange={onChange}
            value={formState.radioRented}
          />
          <Form.Check
            type="radio"
            label="Reservado"
            name="radioSoldOrReserved"
            id="radioReserved"
            disabled={disabledReserved}
            checked={formState.radioReserved}
            onChange={onChange}
            value={formState.radioReserved}
          />
          <Form.Check
            type="radio"
            label="Disponible"
            name="radioSoldOrReserved"
            id="radioAvailable"
            disabled={disabledAvailable}
            checked={formState.radioAvailable}
            onChange={onChange}
            value={formState.radioAvailable}
          />
        </Form.Group>

        <Form.Group as={Col} md="3" sm="6" xs="6" className="container-switch">
          <Form.Label as="label">Otras Caracteristicas</Form.Label>
          <Form.Check
            id="lift"
            type="switch"
            label="Ascensor"
            onChange={onChange}
            value={formState.lift}
            checked={formState.lift}
          />
          <Form.Check
            id="pool"
            type="switch"
            label="Piscina"
            onChange={onChange}
            value={formState.pool}
            checked={formState.pool}
          />
          <Form.Check
            id="parking"
            type="switch"
            label="Plaza de parking"
            onChange={onChange}
            value={formState.parking}
            checked={formState.parking}
          />
          <Form.Check
            id="yard"
            type="switch"
            label="Patio"
            onChange={onChange}
            value={formState.yard}
            checked={formState.yard}
          />
          <Form.Check
            id="storageroom"
            type="switch"
            label="Trastero"
            onChange={onChange}
            value={formState.storageroom}
            checked={formState.storageroom}
          />
          <Form.Check
            id="builtClosets"
            type="switch"
            label="Armarios empotrados"
            onChange={onChange}
            value={formState.builtClosets}
            checked={formState.builtClosets}
          />
          <Form.Check
            id="airConditioning"
            type="switch"
            label="Aire acondicionado"
            onChange={onChange}
            value={formState.airConditioning}
            checked={formState.airConditioning}
          />
          <Form.Check
            id="terrace"
            type="switch"
            label="Terraza"
            onChange={onChange}
            value={formState.terrace}
            checked={formState.terrace}
          />
          <Form.Check
            id="balcony"
            type="switch"
            label="Balcón"
            onChange={onChange}
            value={formState.balcony}
            checked={formState.balcony}
          />
        </Form.Group>
        <Form.Group as={Col} md="3" sm="6" xs="6">
          <Form.Label>Año de construcción</Form.Label>
          <InputGroup>
            <Form.Control
              id="builtYear"
              name="builtYear"
              type="text"
              placeholder="Año de construcción"
              onChange={onChangeNumber}
              value={formState.builtYear}
            />
          </InputGroup>
          <Form.Label>Superficie construida</Form.Label>
          <InputGroup>
            <Form.Control
              id="builtArea"
              name="builtArea"
              type="text"
              placeholder="Superficie construida"
              aria-describedby="inputGroupPrepend"
              onChange={onChangeNumber}
              value={formState.builtArea}
            />
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">m2</InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
          <Form.Label>Superficie útil</Form.Label>
          <InputGroup>
            <Form.Control
              id="usefulSurface"
              name="usefulSurface"
              type="text"
              placeholder="Superficie útil"
              aria-describedby="inputGroupPrepend"
              onChange={onChangeNumber}
              value={formState.usefulSurface}
            />
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">m2</InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
          <Form.Label>Superficie parcela</Form.Label>
          <InputGroup>
            <Form.Control
              id="surfacePlot"
              name="surfacePlot"
              type="text"
              placeholder="Superficie parcela"
              aria-describedby="inputGroupPrepend"
              onChange={onChangeNumber}
              value={formState.surfacePlot}
            />
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">m2</InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="3" sm="6" xs="6" className="container-switch">
          <Form.Label as="label">Orientación</Form.Label>
          <Form.Check
            id="northOrientation"
            type="switch"
            label="NORTE"
            onChange={onChange}
            value={formState.northOrientation}
            checked={formState.northOrientation}
          />
          <Form.Check
            id="eastOrientation"
            type="switch"
            label="ESTE"
            onChange={onChange}
            value={formState.eastOrientation}
            checked={formState.eastOrientation}
          />
          <Form.Check
            id="southOrientation"
            type="switch"
            label="SUR"
            onChange={onChange}
            value={formState.southOrientation}
            checked={formState.southOrientation}
          />
          <Form.Check
            id="westOrientation"
            type="switch"
            label="OESTE"
            onChange={onChange}
            value={formState.westOrientation}
            checked={formState.westOrientation}
          />
        </Form.Group>
        <Form.Group as={Col} md="3" sm="6" xs="6" className="container-radio">
          <Form.Label as="label">Calefacción</Form.Label>
          <Form.Check
            id="individualHeating"
            type="radio"
            name="Heating"
            label="Individual"
            onChange={onChange}
            value={formState.individualHeating}
            checked={formState.individualHeating}
          />
          <Form.Check
            id="centralHeating"
            type="radio"
            name="Heating"
            label="Centralizada"
            onChange={onChange}
            value={formState.centralHeating}
            checked={formState.centralHeating}
          />
          <Form.Check
            id="heatingNotAvailable"
            type="radio"
            name="Heating"
            label="No disponible"
            onChange={onChange}
            value={formState.heatingNotAvailable}
            checked={formState.heatingNotAvailable}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" sm="6" xs="6" className="container-highlighted">
          <Form.Label as="label">Destacar</Form.Label>
          <Form.Check
            id="highlighted"
            type="switch"
            label="destacado"
            onChange={onChange}
            value={formState.highlighted}
            checked={formState.highlighted}
          />
          <Form.Control
            id="highlightedText"
            name="highlightedText"
            as="textarea"
            placeholder="Frase para destacado"
            rows={3}
            onChange={onChange}
            value={formState.highlightedText}
            disabled={!formState.highlighted}
          />
        </Form.Group>
        <Col className="text-center" md={3} sm="6" xs="6">
          {highlightedImage !== undefined && (
            <Container>
              <Row>
                Imagen destacada <FontAwesomeIcon icon={faGem} color="#D4AF37" />
              </Row>
              <Row>
                <Figure className="text-right">
                  {!highlightedImage.isUpdated && (
                    <Figure.Image
                      thumbnail
                      alt={highlightedImage.picture.name}
                      src={URL.createObjectURL(highlightedImage.picture)}
                      id={highlightedImage.id}
                      className="preview-main-form"
                    />
                  )}
                  {highlightedImage.isUpdated && (
                    <Figure.Image
                      thumbnail
                      alt={highlightedImage.picture.name}
                      src={highlightedImage.picture}
                      id={highlightedImage.id}
                      className="preview-main-form"
                    />
                  )}
                  <Figure.Caption className="picture-name">{highlightedImage.picture.name}</Figure.Caption>
                </Figure>
              </Row>
            </Container>
          )}
        </Col>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="12">
          {/* <Form.File
            id="custom-file"
            label="Custom file input"
            label={formState.media.length > 0 ? formState.media.length + " ficheros selecionados" : "Imágenes"}
            multiple
            custom
            onChange={onChargeImages}
            accept="image/*"
          /> */}
          <div className="custom-file mt-2">
            <input
              id="inputGroupFile04"
              type="file"
              multiple
              className="custom-file-input"
              multiple
              onChange={onChargeImages}
              accept="image/*, video/*"
            />
            <label className="custom-file-label" htmlFor="inputGroupFile04">
              <span className="d-inline-block text-truncate w-75">Imagenes</span>
            </label>
          </div>
        </Form.Group>
      </Form.Row>

      <Row>
        <Col className="text-right button-right">
          <Button type="submit">
            {isUpdated && (
              <>
                <FontAwesomeIcon icon={faEdit} color="#FFFFFF" /> Actualizar
              </>
            )}
            {!isUpdated && (
              <>
                <FontAwesomeIcon icon={faSave} color="#FFFFFF" /> Guardar
              </>
            )}
          </Button>
        </Col>
      </Row>

      <Row>
        <Col className="text-left button-left">
          <Button
            type="button"
            disabled={mediaRemove === undefined || (mediaRemove !== undefined && mediaRemove.length === 0)}
            variant="danger"
            onClick={removeMediaElements}
          >
            Eliminar imagenes <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </Col>
      </Row>

      <Row>
        {formState.media.length > 0 && (
          <ListImages
            media={formState.media}
            setMediaRemove={setMediaRemove}
            setHighlightedImage={imageHighlighted}
            isUpdated={isUpdated}
          />
        )}
      </Row>
    </Form>
  );
};

export default AdminFormHouse;
