import React, { useState, useEffect } from "react";
import "./AdminMain.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify"; 

import { Search, PlusCircle, X } from "react-bootstrap-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faHome } from "@fortawesome/free-solid-svg-icons";

import FormHouse from "../admin-form-house/Admin-FormHouse";
import Notifications from "../commons/Notifications/CustomNotification";

import AdminListHouses from "../admin-list-houses/AdminListHouses";
import FooterCopyright from "../footer-copyright/footer-copyright";

function AdminMain() {
  const [user, updateUser] = useState(() => {
    checkUser();
  }, []);
  const [formState, setFormState] = useState({ ref: "", city: "" });
  const [visibilidad, setVisibilidad] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState(null);
  const [houseSelected, setHouseSelected] = useState(null);

  useEffect(() => {
    return () => {
      scrollToTop();
    };
  }, [visibilidad]);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function setSelected(reference) {
    setHouseSelected(reference);
    setVisibilidad(true);
  }

  function addNotification(notification) {
    let currentNotifications = [...notifications];
    currentNotifications.push(notification);
    setNotifications(currentNotifications);
  }

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      updateUser(user);
    } catch (error) {
      console.log("AdminMain CheckUser ERROR: ", error);
    }
  }

  function onChange(e) {
    e.persist();
    setFormState(() => ({
      ...formState,
      [e.target.id]: e.target.value,
    }));
  }

  function search() {
    let filter = {};
    if (formState.ref !== "") {
      filter.reference = { eq: formState.ref };
    }

    if (formState.city !== "") {
      filter.city = { eq: formState.city };
    }
    if (Object.entries(filter).length > 0) {
      setFilter(filter);
    } else {
      setFilter(null);
    }
  }

  async function logout() {
    await Auth.signOut();
  }

  async function globalLogout() {
    await Auth.signOut({ global: true });
  }

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: "relative",
        minHeight: "100px",
      }}
    >
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="#home">
          <img
            alt=""
            src="https://harunnaapp89ba092d890f4a839722aea67392711c140000-dev.s3-eu-west-1.amazonaws.com/harruna-logo-fondo-blanco.jpeg"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Hola {user !== undefined ? user.username : ""}
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-center">
          <Nav className="mr-auto nav-home">
            <Nav.Link href="/">
              Ir a{" "}
              <Navbar.Text>
                <FontAwesomeIcon icon={faHome} color="white" />
              </Navbar.Text>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <NavDropdown title="opciones" id="collasible-nav-dropdown">
            <NavDropdown.Item onClick={globalLogout}>
              <FontAwesomeIcon icon={faPowerOff} color="black" /> Cerrar todas <br />
              las sesiones
            </NavDropdown.Item>
          </NavDropdown>
          <Navbar.Text onClick={logout}>
            <FontAwesomeIcon icon={faPowerOff} color="white" /> Cerrar sesión
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
      <Notifications notifications={notifications} setNotifications={setNotifications} />
      <Jumbotron fluid>
        {!visibilidad && (
          <Container fluid>
            <Row className="rows-admin-list">
              <Col>
                <h1>
                  <Badge variant="light">Mis casas</Badge>
                </h1>
              </Col>
            </Row>
            <Row className="rows-admin-list justify-content-md-center">
              <Container>
                <Form>
                  <Row>
                    <Col md={4} lg={4} sm={12}>
                      <Form.Control placeholder="#Ref" type="text" id="ref" name="ref" onChange={onChange} />
                    </Col>
                    <Col md={4} lg={4} sm={12}>
                      <Form.Control placeholder="Ciudad" type="text" id="city" name="city" onChange={onChange} />
                    </Col>
                    <Col md={2} lg={2} sm={12}>
                      <Button variant="primary" type="button" block onClick={search}>
                        Buscar <Search />
                      </Button>
                    </Col>
                    <Col md={2} lg={2} sm={12}>
                      <Button
                        variant="success"
                        type="button"
                        onClick={() => {
                          setVisibilidad(true);
                        }}
                        block
                      >
                        Crear <PlusCircle />
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Row>
            <Row>
              <AdminListHouses currentFilter={filter} setHouseSelected={setSelected} />
            </Row>
          </Container>
        )}
        {visibilidad && (
          <Container fluid>
            <Row>
              <Col sm={3} xs={3} md={3} lg={3}>
                <h1>
                  <Badge variant="light">Crear casa</Badge>
                </h1>
              </Col>
              <Col className="text-right button-right">
                <Button
                  className="button-cancel-margin"
                  variant="danger"
                  type="button"
                  onClick={() => {
                    setVisibilidad(false);
                    setHouseSelected(null);
                    scrollToTop();
                  }}
                >
                  Cancelar <X />
                </Button>
              </Col>
            </Row>
            <FormHouse addNotifications={addNotification} returnListHouses={setVisibilidad} house={houseSelected} />
          </Container>
        )}
      </Jumbotron>
      <Container fluid id="footer">
        {/* <AmplifySignOut /> */}
        <FooterCopyright />
      </Container>
    </div>
  );
}

export default withAuthenticator(AdminMain);
