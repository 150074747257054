import React from "react";
import "./GuestBannerIndex.css";

// import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

function GuestBannerIndex() {
  return (
    <Container fluid className="banner-info">
      <div className="banner-img"></div>
      <Row className="justify-content-md-center">
        <Col lg={4}>
          <h5>HARUNNA INVESTMENTS</h5>
          <div className="linea"></div>
          <div className="title-block-4">
            <p className="texto-group-banner">
              Como REAL STATE y GRUPO DE INVERSIÓN, comprometida con sus clientes, comparte la responsabilidad de
              ofrecer lo mejor del mercado actual en competencia, asesoramiento en gestiones bancarias con la máxima y
              completa diligencia personal. <br></br>
              <br></br>
              <br></br>"Juntos somos invencibles ayudando a cumplir sueños".
            </p>
          </div>
        </Col>
        <Col lg={3}>
          <h5>SOBRE NOSOTROS</h5>
          <div className="linea"></div>
          <ListGroup variant="flush" className="list-group-banner">
            <ListGroup.Item>
              <a href="/about">Sobre nosotros</a>
            </ListGroup.Item>
            <ListGroup.Item>
              <a href="/aviso-legal">Aviso legal</a>
            </ListGroup.Item>
            <ListGroup.Item>
              <a href="/politica-de-privacidad">Política de privacidad</a>
            </ListGroup.Item>
            <ListGroup.Item>
              <a href="/politica-de-cookies">Política de Cookies</a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col lg={3}>
          <h5>CONTACTO</h5>
          <div className="linea"></div>
          <ListGroup variant="flush" className="list-group-banner">
            <ListGroup.Item>
              <FontAwesomeIcon icon={faMap} color="white" />{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/Zaruna+Cosmetics/@40.4634334,-3.6689457,19.75z/data=!4m5!3m4!1s0xd42292e6b3ce913:0xfb8da94613da1916!8m2!3d40.4635162!4d-3.668458"
              >
                &nbsp;&nbsp;C/ Dracena,&nbsp;&nbsp;nº&nbsp;&nbsp;34&nbsp;&nbsp;-&nbsp;&nbsp;28016&nbsp;&nbsp;Madrid.
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <a href="tel:+34626256144">
                <FontAwesomeIcon icon={faPhone} color="white" /> +34 626 256 144.
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <FontAwesomeIcon icon={faEnvelope} color="white" /> info@harunnainvestments.com
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default GuestBannerIndex;
