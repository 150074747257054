import React from "react";
import "./App.css";
import AdminLogin from "./admin-login/AdminLogin";
import GuestMain from "./guest-main/GuestMain";
import GuestAboutMain from "./guest-about/GuestAboutMain";
import GuestServicesMain from "./guest-services/GestServicesMain";
import GuestDetail from "./guest-detail/GuestDetail";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import GuestAvisoLegal from "./guest-main/guest-aviso-legal/GuestAvisoLegal";
import GuestPrivacidad from "./guest-main/guest-privacidad/GuestPrivacidad";
import GuestCookies from "./guest-main/guest-cookies/GuestCookies";
import Search from "./guest-search/GuestSearch";
import GuestContactoMain from "./guest-contacto/GuestContactoMain";
import NotFound from "./page-404/Page404";

function App() {
  return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={GuestMain} />
          <Route path="/about" exact component={GuestAboutMain} />
          <Route path="/services" exact component={GuestServicesMain} />
          <Route path="/admin" exact component={AdminLogin} />
          <Route path="/search" exact component={Search} />
          <Route path="/details/:reference" exact component={GuestDetail} />
          <Route path="/aviso-legal" exact component={GuestAvisoLegal} />
          <Route path="/politica-de-privacidad" exact component={GuestPrivacidad} />
          <Route path="/politica-de-cookies" exact component={GuestCookies} />
          <Route path="/contact" exact component={GuestContactoMain} />
          <Route path="*" exact component={NotFound} />
        </Switch>
      </BrowserRouter>
  );
}

export default App;
