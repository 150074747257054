import React, { useEffect } from "react";
import "./footer.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";

import CookieConsent from "react-cookie-consent";

function FooterCopyright() {
  function currentYear() {
    return new Date().getFullYear();
  }

  return (
    <Container fluid className="justify-content-center guest-footer">
      <Row className="justify-content-md-center">
        <Col xs lg="8">
          <FontAwesomeIcon icon={faCopyright} size="2x" color="black" /> {currentYear()} Harunna Investments
        </Col>
        <Col xs lg="1">
          <a
            href="https://wa.me/34626256144?text=Hola,%20estoy%20interesada/o%20en%20una%20vivienda"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faWhatsapp} size="2x" color="black" />{" "}
          </a>
        </Col>
        <Col xs lg="1">
          <a href="" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="2x" color="black" />{" "}
          </a>
        </Col>
        <Col xs lg="1">
          <a href="http://instagram.com/harunnainvestment" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" color="black" />
          </a>
        </Col>
      </Row>

      <CookieConsent
        location="bottom"
        buttonText="X"
        cookieName="accepts-cookies"
        style={{ background: "rgba(218, 187, 104, 0.93)", color: "#FFFFFF", fontSize: "16px" }}
        buttonStyle={{ color: "#FFFFFF", background: "rgba(0, 0, 0, 0.2)", fontSize: "18px" }}
        expires={30}
      >
        Este sitio web utiliza cookies para obtener datos estadísticos de la navegación de sus usuarios. Si continúas
        navegando, consideramos que aceptas su uso. <a href="/politica-de-cookies">Politica de cookies</a>
      </CookieConsent>
    </Container>
  );
}

export default FooterCopyright;
