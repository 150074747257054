import React, { useState, useEffect } from "react";
import "./GuestHighlighted.css";

import star from "../../img/estrella.svg";

import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import { getHighlighted } from "../../graphql/custom-queries";
import { Storage, API } from "aws-amplify";

function GuestHighlighted() {
  const [houseHighlighted, setHouseHighlighted] = useState([]);

  useEffect(() => {
    loadHighlighted();
  }, []);

  async function loadHighlighted() {
    let result = await API.graphql({ query: getHighlighted, variables: { limit: 3 } });

    if (result?.data?.housesByModelType?.items) {
      let houseHighlighted = [];
      Promise.all(
        result.data.housesByModelType.items.slice(0, 3).map(async (item) => {
          let house = {
            highlighted: item.highlighted,
            highlightedText: item.highlightedText,
            reference: "/details/" + item.reference,
            title: item.title,
          };
          house.media = await getMediaHighlighted(item);
          houseHighlighted.push(house);
        }),
      ).then((then) => {
        setHouseHighlighted(houseHighlighted);
      });
    }
  }

  async function getMediaHighlighted(item) {
    let imageToUse;
    if (item.mediaHighlighted) {
      imageToUse = item.mediaHighlighted;
    } else if (item.media.length > 0) {
      imageToUse = item.media[0];
    } else {
      console.warn("No hay imagenes para cargar");
      return "";
    }
    if (imageToUse) {
      let toReturn = await Storage.get(imageToUse, {
        level: "public",
      });
      return toReturn;
    }
  }

  return (
    <Container className="marketing">
      <Row className="title">
        <Col lg={12}>
          <img src={star} className="App-logo" alt="logo" />
        </Col>
      </Row>
      <Row>
        {houseHighlighted.length > 0 &&
          houseHighlighted.map((item, index) => (
            <Col lg={4} key={index}>
              <a href={item.reference}>
                <Image src={item.media} thumbnail className="img-highlighted" />
              </a>
              <h2>{item.title}</h2>
              <p>
                {item.highlightedText.split("\n").map(function (item, index) {
                  return (
                    <span key={index}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </p>
              <p className="texto-destacados">
                <a className="btn btn-secondary" href={item.reference} role="button">
                  Ver más »
                </a>
              </p>
            </Col>
          ))}
      </Row>
    </Container>
  );
}

export default GuestHighlighted;
