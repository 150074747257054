import React, { useState, useEffect } from "react";

import "./GuestSearch.css";

import computer from "../img/icon/computer.svg";

import GuestNavbar from "../guest-main/guest-navbar/GuestNavbar";
import Footer from "../guest-main/footer/footer";
import BannerInfo from "../guest-main/guest-banner-index/GuestBannerIndex";
import SearchForm from "./form/GuestSearchForm";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Spinner from "react-bootstrap/Spinner";

import InfiniteScroll from "react-infinite-scroll-component";

import ImageGallery from "react-image-gallery";

import FilterIcon from "../img/icon/button-filter.svg";

import { API, Storage } from "aws-amplify";

import { listHouseTypes, listStatuss } from "../graphql/queries";

import { listCities, filteredListHouses } from "../graphql/custom-queries";

import ProgressUploadFiles from "../commons/ProgressUploadFiles/ProgressUploadFiles";

const initialState = {
  typeHouse: [0],
  houseStatus: [0],
  zone: [0],
  radioRent: false,
  radioSale: true,
  radioRentSale: false,
  minPrice: "",
  maxPrice: "",
  minSurface: "",
  maxSurface: "",
  nBedrooms: [0],
  lift: false,
  pool: false,
  parking: false,
  yard: false,
  storageroom: false,
  builtClosets: false,
  airConditioning: false,
  terrace: false,
  balcony: false,
};

function GuestSearch() {
  const [formState, setFormState] = useState(() => {
    let storageFormState = localStorage.getItem("currentFormState");
    if (storageFormState) {
      let form = JSON.parse(storageFormState);
      if (form.minPrice) form.minPrice = buildCommas(form.minPrice);
      if (form.maxPrice) form.maxPrice = buildCommas(form.maxPrice);
      return form;
    }
    return initialState;
  });
  const [filter, setFilter] = useState(() => {
    let storageFilter = localStorage.getItem("currentFilter");
    if (storageFilter) {
      return JSON.parse(storageFilter);
    } else {
      return {
        showAfterSoldOrRent: { eq: true },
      };
    }
  });
  const [houseTypes, setHouseTypes] = useState([]);
  const [houseStatus, setHouseStatus] = useState([]);
  const [cities, setCities] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [listEstates, setListEstates] = useState([]);
  const [nextStateToken, setNextStateToken] = useState(null);
  const [divLoader, setDivLoader] = useState(true);

  useEffect(() => {
    fetchHouseType();
    fetchCities();
    fetchHouseStatus();
    fetchHouses();
  }, []);

  useEffect(() => {
    fetchHouses();
    setDivLoader(false);
  }, [filter]);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  async function callHouses(tokenToCall) {
    const apiData = await API.graphql({
      query: filteredListHouses,
      variables: { filter: filter, nextToken: tokenToCall },
    });
    if (apiData.data.housesByModelType.items.length < 7 && apiData.data.housesByModelType.nextToken) {
      let currentHouses = await callHouses(apiData.data.housesByModelType.nextToken);
      if (apiData.data.housesByModelType.items.length === 0) {
        apiData.data.housesByModelType.items = currentHouses.data.housesByModelType.items;
      } else {
        apiData.data.housesByModelType.items.concat(currentHouses.data.housesByModelType.items);
      }
      apiData.data.housesByModelType.nextToken = currentHouses.data.housesByModelType.nextToken;
    }
    return apiData;
  }

  async function fetchHouses() {
    const apiData = await callHouses(nextStateToken);
    setNextStateToken(apiData.data.housesByModelType.nextToken);
    loadData(apiData);
  }

  async function loadData(apiData) {
    let listEstate = [];
    let listMedia = [];
    if (apiData) {
      apiData.data.housesByModelType.items.map((item) => {
        listEstate.push({
          media: [],
          title: item.title,
          price: buildCommas(item.price),
          bedrooms: item.bedrooms,
          surface: item.surface,
          highlightedText: item.highlightedText,
          description: cutDescription(item.description),
          reference: item.reference,
        });
        listMedia.push({
          media: filterMedia(item.media),
          reference: item.reference,
        });
      });
      loadImages(listEstate, listMedia);
    } else {
      setListEstates([]);
    }
  }

  function filterMedia(media) {
    return media.filter((item) => !item.startsWith("video-"));
  }

  function cutDescription(description) {
    if (description.length > 200) {
      return description.substr(0, 200) + "...";
    }
    return description;
  }

  async function loadImages(listEstate, listMedia) {
    let urlImages = [];
    let allResolved = Promise.all(
      await listMedia.map(async (item) => {
        return Promise.all(
          await item.media.map(async (image) => {
            let url = await Storage.get(image, {
              level: "public",
            });
            urlImages.push({ url: url, reference: item.reference });
          }),
        );
      }),
    );

    allResolved.then((then) => {
      listEstate.map((item) => {
        let images = [];
        urlImages.map((image) => {
          if (item.reference === image.reference) {
            images.push({
              original: image.url,
              thumbnail: "",
            });
          }
        });
        item.media = images;
      });
      if (listEstates.length > 0) {
        setListEstates([...listEstates].concat(listEstate));
      } else {
        setListEstates(listEstate);
      }
    });
  }

  async function fetchHouseType() {
    const apiData = await API.graphql({ query: listHouseTypes });
    let currentHouseTypes = [];
    apiData.data.listHouseTypes.items.map((item) => {
      currentHouseTypes.push({
        id: item.uuid,
        description: item.type,
      });
    });
    setHouseTypes(currentHouseTypes);
  }

  async function fetchHouseStatus() {
    const apiData = await API.graphql({ query: listStatuss });
    let currentHouseStatus = [];
    apiData.data.listStatuss.items.map((item) => {
      currentHouseStatus.push({
        id: item.uuid,
        description: item.status,
      });
    });
    setHouseStatus(currentHouseStatus);
  }

  async function fetchCities() {
    const apiData = await API.graphql({ query: listCities });
    let currentCities = [];
    apiData.data.listHouses.items.map((item, index) => {
      let exist = currentCities.filter((itemFiltered) => itemFiltered.description === item.city);
      if (exist.length === 0) {
        currentCities.push({
          id: index + 1,
          description: item.city,
        });
      }
    });
    setCities(currentCities);
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function addCommas(e) {
    e.persist();
    let nStr = e.target.value;
    setFormState(() => ({
      ...formState,
      [e.target.id]: buildCommas(nStr),
    }));
  }

  function buildCommas(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  }

  function removeCommas(e) {
    e.persist();
    let value = delCommas(e.target.value);
    if (e.target && value) {
      setFormState(() => ({
        ...formState,
        [e.target.id]: value,
      }));
    }
  }

  function delCommas(value) {
    let val = value.replaceAll(".", "");
    if (val.length > 0) {
      return val;
    }
    return "";
  }

  function onChange(e) {
    e.persist();
    let copyState = { ...formState };
    if (e.target.type === "select-multiple") {
      let selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
      copyState[e.target.id] = selectedOptions;
    } else if (e.target.type === "checkbox") {
      copyState[e.target.id] = e.target.checked;
    } else if (e.target.type === "radio") {
      copyState.radioRent = false;
      copyState.radioSale = false;
      copyState.radioRentSale = false;
      copyState[e.target.id] = e.target.translate;
    } else {
      copyState[e.target.id] = e.target.value;
    }
    localStorage.setItem("currentFormState", JSON.stringify(copyState));
    setFormState(copyState);
  }

  function onChangeNumber(e) {
    e.persist();
    let value = e.target.value;

    const numberRegExp = /^[0-9]+$|^$/;
    const number2DecimalsRegExp = /^[0-9]+\.[0-9]{1,2}$|^$/;
    const numberOnlyDotRegExp = /^[0-9]+\.$|^$/;

    let valueToUpdate = value;

    let updateState = false;

    if (e.target.id === "minPrice" || e.target.id === "maxPrice") {
      if (numberRegExp.test(value)) {
        updateState = true;
        valueToUpdate = value === "" || value === "0" ? value : Number(value);
      } else if (number2DecimalsRegExp.test(value)) {
        updateState = true;
        valueToUpdate = value === "" || value === "0" ? value : Number(value);
      } else if (numberOnlyDotRegExp.test(value)) {
        updateState = true;
        valueToUpdate = value;
      }
    }
    if (updateState) {
      setFormState(() => ({
        ...formState,
        [e.target.id]: valueToUpdate,
      }));
    }
  }

  async function searching() {
    setDivLoader(true);
    let currentFilter = buildFilter(formState);

    if (Object.entries(currentFilter).length > 0) {
      setFilter(currentFilter);
      setNextStateToken(null);
      localStorage.setItem("currentFilter", JSON.stringify(currentFilter));
      scrollToTop();
      setListEstates([]);
      scrollToTop();
    }
    setShowFilter(false);
  }

  function buildFilter(data) {
    let copyState = { ...formState };
    let currentFilter = {
      showAfterSoldOrRent: { eq: true },
    };
    //TypeHouse
    if (!data.typeHouse.some((some) => some === 0)) {
      let currentAnd = buildAnd(data.typeHouse, houseTypes, { houseType: {} }, "houseType");
      if (currentAnd) {
        currentFilter.and = currentAnd;
      }
    }
    //houseStatus
    if (!data.houseStatus.some((some) => some === 0)) {
      let currentAnd = buildAnd(data.houseStatus, houseStatus, { houseStatus: {} }, "houseStatus");
      if (currentAnd) {
        if (!currentFilter.and) {
          currentFilter.and = currentAnd;
        } else if (currentFilter.and && !currentFilter.and.and) {
          currentFilter.and.and = currentAnd;
        }
      }
    }
    //City
    if (!data.zone.some((some) => some === 0)) {
      let currentAnd = buildAnd(data.zone, cities, { city: {} }, "city");
      if (currentAnd) {
        if (!currentFilter.and) {
          currentFilter.and = currentAnd;
        } else if (currentFilter.and && !currentFilter.and.and) {
          currentFilter.and.and = currentAnd;
        } else if (currentFilter.and && currentFilter.and.and && !currentFilter.and.and.and) {
          currentFilter.and.and.and = currentAnd;
        }
      }
    }
    //Operation
    if (data.radioRent) {
      currentFilter.rent = { eq: true };
    } else if (data.radioSale) {
      currentFilter.sale = { eq: true };
    } else if (data.radioRentSale) {
      currentFilter.rentSaleOrRent = { eq: true };
    }
    //Price
    if (data.minPrice !== "" && data.minPrice !== "0" && data.maxPrice !== "" && data.maxPrice !== "0") {
      let price = {};
      let minPrice = Number(delCommas(data.minPrice));
      let maxPrice = Number(delCommas(data.maxPrice));
      if (minPrice > maxPrice) {
        price = {
          between: [maxPrice, minPrice],
        };
        //Actualizar state
        copyState.minPrice = buildCommas(maxPrice);
        copyState.maxPrice = buildCommas(minPrice);
      } else {
        price = {
          between: [minPrice, maxPrice],
        };
      }
      if (price.between) {
        currentFilter.price = price;
      }
    } else if (data.minPrice !== "" && data.minPrice !== "0" && (data.maxPrice === "" || data.maxPrice === "0")) {
      currentFilter.price = {
        ge: Number(delCommas(data.minPrice)),
      };
    } else if (data.maxPrice !== "" && data.maxPrice !== "0" && (data.minPrice === "" || data.minPrice === "0")) {
      currentFilter.price = {
        le: Number(delCommas(data.maxPrice)),
      };
    }

    //Surface
    if (data.minSurface !== "" && data.minSurface !== "0" && data.maxSurface !== "" && data.maxSurface !== "0") {
      let surface = {};
      let minSurface = Number(data.minSurface);
      let maxSurface = Number(data.maxSurface);
      if (minSurface > maxSurface) {
        surface = {
          between: [maxSurface, minSurface],
        };
        //Actualizar state
        copyState.minSurface = maxSurface;
        copyState.maxSurface = minSurface;
      } else {
        surface = {
          between: [minSurface, maxSurface],
        };
      }
      if (surface.between) {
        currentFilter.surface = surface;
      }
    } else if (
      data.minSurface !== "" &&
      data.minSurface !== "0" &&
      (data.maxSurface === "" || data.maxSurface === "0")
    ) {
      currentFilter.surface = {
        ge: Number(data.minSurface),
      };
    } else if (
      data.maxSurface !== "" &&
      data.maxSurface !== "0" &&
      (data.minSurface === "" || data.minSurface === "0")
    ) {
      currentFilter.surface = {
        le: Number(data.maxSurface),
      };
    }

    //beedrooms
    if (!data.nBedrooms.some((some) => Number(some) === 0)) {
      let bedrooms = {};
      if (data.nBedrooms.some((some) => Number(some) === 4)) {
        bedrooms.ge = Number(data.nBedrooms.reduce((a, b) => Math.min(a, b))) - 1;
      } else if (data.nBedrooms.length > 1) {
        let minor = Number(data.nBedrooms.reduce((a, b) => Math.min(a, b)));
        let mayor = Number(data.nBedrooms.reduce((a, b) => Math.max(a, b)));
        bedrooms.between = [minor, mayor];
      } else {
        bedrooms.eq = Number(data.nBedrooms[0]) - 1;
      }
      if (Object.entries(bedrooms).length > 0) {
        currentFilter.bedrooms = bedrooms;
      }
    }

    // features
    if (data.lift) currentFilter.lift = { eq: true };
    if (data.pool) currentFilter.pool = { eq: true };
    if (data.parking) currentFilter.parking = { eq: true };
    if (data.yard) currentFilter.yard = { eq: true };
    if (data.storageroom) currentFilter.storageRoom = { eq: true };
    if (data.builtClosets) currentFilter.builtClosets = { eq: true };
    if (data.airConditioning) currentFilter.airConditioning = { eq: true };
    if (data.terrace) currentFilter.terrace = { eq: true };
    if (data.balcony) currentFilter.balcony = { eq: true };

    setFormState(copyState);
    return currentFilter;
  }

  function buildAnd(formData, stateDate, objectBuild, named) {
    let and = {
      or: [],
    };
    formData.map((item) => {
      let current = stateDate.filter((typeItem) => typeItem.id === item);
      if (current.length > 0) {
        let copyObjectBuild = JSON.parse(JSON.stringify(objectBuild));
        copyObjectBuild[named].eq = current[0].description;
        and.or.push(copyObjectBuild);
      }
    });
    if (and.or.length > 0) {
      return and;
    }
    return null;
  }

  function clearFilter() {
    setDivLoader(true);
    setFormState(initialState);
    localStorage.removeItem("currentFormState");
    localStorage.removeItem("currentFilter");
  }

  return (
    <>
      <GuestNavbar />
      {divLoader && <ProgressUploadFiles />}
      <Container className="search-container">
        <Row className="row-button-filter">
          <Col xs={4} sm={4} className="text-right button-right">
            <Button className="filer-button" onClick={() => setShowFilter(!showFilter)}>
              <img src={FilterIcon} alt="icon-filter" height="25" /> Filtrar
            </Button>
          </Col>
        </Row>
        {showFilter && (
          <Row>
            <Col md={12} className="col-container-search-small">
              <SearchForm
                commonState={formState}
                cities={cities}
                houseStatus={houseStatus}
                houseTypes={houseTypes}
                onChange={onChange}
                searching={searching}
                clearFilter={clearFilter}
                addCommas={addCommas}
                removeCommas={removeCommas}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col md={3} className="col-container-search">
            <SearchForm
              commonState={formState}
              cities={cities}
              houseStatus={houseStatus}
              houseTypes={houseTypes}
              onChange={onChange}
              searching={searching}
              clearFilter={clearFilter}
              addCommas={addCommas}
              removeCommas={removeCommas}
            />
          </Col>
          <Col md={9}>
            {!showFilter && (
              <Container fluid className="container-result">
                <InfiniteScroll
                  dataLength={listEstates.length}
                  next={fetchHouses}
                  hasMore={nextStateToken}
                  loader={
                    <Row className="row-estate loading">
                      <Col>
                        <h4>
                          Loading <Spinner animation="grow" variant="dark" />{" "}
                          <Spinner animation="grow" variant="dark" /> <Spinner animation="grow" variant="dark" />
                        </h4>
                      </Col>
                    </Row>
                  }
                  height={1230}
                  endMessage={
                    <Row className="end-row" style={{ textAlign: "center" }}>
                      <Col>
                        <Image src={computer} rounded height={50} />{" "}
                        <b>
                          ¿No encuentras tu casa perfecta?{" "}
                          <a href="/contact">
                            <Button variant="outline-info">Contacta con nosotros</Button>
                          </a>{" "}
                          y te ayudaremos.
                        </b>
                      </Col>
                    </Row>
                  }
                >
                  {listEstates.map((item) => (
                    <Row key={item.reference} className="row-estate">
                      <Col md={4}>
                        <ImageGallery lazyLoad={true} showThumbnails={false} items={item.media} />
                      </Col>
                      <Col md={8} className="summary">
                        <Container fluid>
                          <Row>
                            <h4>{item.title}</h4>
                          </Row>
                          <Row>
                            <h5>{item.price},00 €</h5>
                          </Row>
                          <Row>
                            <h5>
                              {item.bedrooms} Habitaciones, {item.surface} m<sup>2</sup>
                            </h5>
                          </Row>
                          {item.highlightedText !== undefined && (
                            <Row className="description">{item.highlightedText}</Row>
                          )}
                          {item.highlightedText === "" && <Row className="description">{item.description}</Row>}
                          <Row className="aling-end-row">
                            <Button variant="secondary" href={"/details/" + item.reference}>
                              Ver más »
                            </Button>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  ))}
                </InfiniteScroll>
              </Container>
            )}
          </Col>
        </Row>
      </Container>
      <BannerInfo />
      <Footer />
    </>
  );
}

export default GuestSearch;
