import { env } from "../config";
const {
  REACT_APP_EMAILJS_RECEIVER: receiverEmail,
  REACT_APP_EMAILJS_TEMPLATEID: templateId,
  REACT_APP_EMAILJS_TEMPLATEID_REFERENCE: templateIdReference,
  REACT_APP_EMAILJS_USERID: user,
} = env;
let send = false;
async function SendEmail({ reference, name, phone, message, subject, replyEmail }) {
  setTimeout(() => {
    if (!reference) {
      window.emailjs
        .send(
          "default_service",
          templateId,
          {
            name,
            phone,
            message,
            subject,
            replyEmail,
            receiverEmail,
          },
          user,
        )
        .then((res) => {
          if (res.status === 200) {
            send = true;
          }
        })
        .catch((err) => console.error("Failed to send feedback. Error: ", err));
    } else {
      window.emailjs
        .send(
          "default_service",
          templateIdReference,
          {
            reference,
            phone,
            message,
            replyEmail,
            receiverEmail,
          },
          user,
        )
        .then((res) => {
          if (res.status === 200) {
            send = true;
          }
        })
        .catch((err) => console.error("Failed to send feedback. Error: ", err));
    }
  }, 1000);
}

export default SendEmail;
