import React, { useState } from "react";
import "./GuestContactoMain.css";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";

import Jumbotron from "react-bootstrap/Jumbotron";

import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faMap, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import GuestNavbar from "../guest-main/guest-navbar/GuestNavbar";
import Footer from "../guest-main/footer/footer";
import BannerInfo from "../guest-main/guest-banner-index/GuestBannerIndex";
import contactIcon from "../img/contact-icon.svg";

import correoElectronicoIcon from "../img/icon/correo-electronico.svg";

import SendEmail from "./SendEmail";

const initialState = {
  name: "",
  replyEmail: "",
  phone: "",
  subject: "",
  message: "",
};

function GuestContactoMain() {
  const [urlMap, setUrlMap] = useState(
    "https://www.google.com/maps/embed/v1/view?key=AIzaSyDQId-u6z5Oau0xdAzHS9zQ5hsg4-_O3so=&center=40.4634334,-3.6689457&zoom=17",
  );
  const [formState, setFormState] = useState(initialState);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmitSuccessful, setFormSubmitSuccessful] = useState(false);
  const { register, errors, handleSubmit } = useForm();

  function onChange(e) {
    e.persist();
    setFormState(() => ({
      ...formState,
      [e.target.id]: e.target.value,
    }));
  }

  function sendMessage() {
    setFormSubmitted(true);
    SendEmail(formState).then((then) => {
      console.log("enviado");
      setTimeout(() => {
        setFormSubmitSuccessful(true);
      }, 2000);
    });
  }

  return (
    <div>
      <GuestNavbar />
      <div className="image-contacto"></div>
      <Jumbotron fluid>
        <Container>
          <Row>
            <Col md={6}>
              <Container fluid>
                <Row className="contact-title justify-content-md-center">
                  <Col xs lg="1">
                    <a
                      href="https://wa.me/34626256144?text=Hola,%20estoy%20interesada/o%20en%20una%20vivienda"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} size="2x" color="goldenrod" />{" "}
                    </a>
                    {/* <FontAwesomeIcon icon={faWhatsapp} size="2x" color="goldenrod" />{" "} */}
                  </Col>
                  <Col xs lg="1">
                    <a href="" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faFacebook} size="2x" color="goldenrod" />{" "}
                    </a>
                    {/* <FontAwesomeIcon icon={faFacebook} size="2x" color="goldenrod" />{" "} */}
                  </Col>
                  <Col xs lg="1">
                    <a href="http://instagram.com/harunnainvestment" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faInstagram} size="2x" color="goldenrod" />
                    </a>
                    {/* <FontAwesomeIcon icon={faInstagram} size="2x" color="goldenrod" /> */}
                  </Col>
                </Row>
                <Row>
                  <Col className="linea-contacto" md={12}></Col>
                </Row>
                <Row className="contact-info">
                  <p>
                    Como REAL STATE y GRUPO DE INVERSIÓN, comprometida con sus clientes, comparte la responsabilidad de
                    ofrecer lo mejor del mercado actual en competencia, asesoramiento en gestiones bancarias con la
                    máxima y completa diligencia personal.{" "}
                  </p>
                </Row>
                <Row className="contact-page-info">
                  <ListGroup variant="flush">
                    <ListGroup.Item className="contact-page-info-item">
                      <a href="tel:+34626256144">
                        <FontAwesomeIcon icon={faPhone} color="black" /> +34 626 256 144.
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item className="contact-page-info-item">
                      <a href="mailto:info@harunnainvestments.com">
                        <FontAwesomeIcon icon={faEnvelope} color="black" /> info@harunnainvestments.com
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item className="contact-page-info-item">
                      <FontAwesomeIcon icon={faMap} color="black" />{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.google.com/maps/place/Zaruna+Cosmetics/@40.4634334,-3.6689457,19.75z/data=!4m5!3m4!1s0xd42292e6b3ce913:0xfb8da94613da1916!8m2!3d40.4635162!4d-3.668458"
                      >
                        &nbsp;&nbsp;C/
                        Dracena,&nbsp;&nbsp;nº&nbsp;&nbsp;34&nbsp;&nbsp;-&nbsp;&nbsp;28016&nbsp;&nbsp;Madrid.
                      </a>
                    </ListGroup.Item>
                  </ListGroup>
                </Row>
                <Row className="row-map">
                  <Col md="12">
                    <iframe
                      title="google-maps"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      style={{ border: 0 }}
                      src={urlMap}
                      allowFullScreen
                    ></iframe>
                  </Col>
                </Row>
              </Container>
            </Col>

            <Col md={6}>
              <Container fluid>
                <Row className="contact-title">
                  <h4>Contacta con nosotros</h4>
                </Row>
                <Row>
                  <Col className="linea-contacto" md={12}></Col>
                </Row>
                <Row className="contact-info">
                  <Col md={12}>
                    {!formSubmitSuccessful && (
                      <Form onSubmit={handleSubmit(sendMessage)}>
                        <Form.Group>
                          <Form.Label>Nombre</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="..."
                            id="name"
                            name="name"
                            onChange={onChange}
                            ref={register({
                              required: {
                                value: true,
                                message: "Debes informar el nombre",
                              },
                            })}
                          />
                          <span className="text-danger text-small d-block mb-2 span-error-app">
                            {errors?.name?.message && (
                              <p>
                                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.name?.message}
                              </p>
                            )}
                          </span>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="@"
                            id="replyEmail"
                            name="replyEmail"
                            onChange={onChange}
                            ref={register({
                              required: {
                                value: true,
                                message: "Debes informar tu correo",
                              },
                              pattern: {
                                value: /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm,
                                message: "El email introducido no es valido",
                              },
                            })}
                          />
                          <span className="text-danger text-small d-block mb-2 span-error-app">
                            {errors?.replyEmail?.message && (
                              <p>
                                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.replyEmail?.message}
                              </p>
                            )}
                          </span>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Telefono</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Teléfono"
                            id="phone"
                            name="phone"
                            onChange={onChange}
                            ref={register({
                              required: {
                                value: true,
                                message: "Debes informar tu telefono",
                              },
                              pattern: {
                                value: /^[0-9]+$/g,
                                message: "El teléfono introducido no es valido",
                              },
                            })}
                          />
                          <span className="text-danger text-small d-block mb-2 span-error-app">
                            {errors?.phone?.message && (
                              <p>
                                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.phone?.message}
                              </p>
                            )}
                          </span>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Asunto</Form.Label>
                          <Form.Control
                            type="text"
                            name="subject"
                            placeholder="..."
                            id="subject"
                            onChange={onChange}
                            ref={register({
                              required: {
                                value: true,
                                message: "Debes el asunto de tu consulta",
                              },
                              minLength: {
                                value: 7,
                                message: "Mínimo 7 carácteres",
                              },
                            })}
                          />
                          <span className="text-danger text-small d-block mb-2 span-error-app">
                            {errors?.subject?.message && (
                              <p>
                                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.subject?.message}
                              </p>
                            )}
                          </span>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Mensaje</Form.Label>
                          <Form.Control
                            id="message"
                            name="message"
                            as="textarea"
                            placeholder="..."
                            rows={6}
                            onChange={onChange}
                            ref={register({
                              required: {
                                value: true,
                                message: "Debes redactar tu consulta",
                              },
                              minLength: {
                                value: 20,
                                message: "Mínimo 20 carácteres",
                              },
                            })}
                          />
                          <span className="text-danger text-small d-block mb-2 span-error-app">
                            {errors?.message?.message && (
                              <p>
                                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.message?.message}
                              </p>
                            )}
                          </span>
                        </Form.Group>
                        <Button
                          variant="primary"
                          type="submit"
                          block
                          className="boton-contacto"
                          disabled={formSubmitted}
                        >
                          <img src={contactIcon} alt="contactIcon-button" /> Enviar
                        </Button>
                      </Form>
                    )}
                    {formSubmitSuccessful && (
                      <Container fluid>
                        <Row>
                          <Col md={12} className="container-sended-icon">
                            <Image src={correoElectronicoIcon} rounded height="100px" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className="container-sended-text">
                            <h3>Mensaje Enviado</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className="container-sended-text">
                            <h5> Se han enviado el mensaje correctamente, nos pondremos en contacto contigo</h5>{" "}
                          </Col>
                        </Row>
                      </Container>
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <BannerInfo />
      <Footer />
    </div>
  );
}

export default GuestContactoMain;
