import React from "react";
import "./GuestAboutMain.css";


import Container from "react-bootstrap/Container";

import Jumbotron from "react-bootstrap/Jumbotron";

import GuestNavbar from "../guest-main/guest-navbar/GuestNavbar";
import Footer from "../guest-main/footer/footer";
import BannerInfo from "../guest-main/guest-banner-index/GuestBannerIndex";

function GuestAboutMain() {
  return (
    <div>
      <GuestNavbar />
      <div className="image-about"></div>
      <Jumbotron className="about-message">
        <Container>
          <h1>UNA EMPRESA CON ALMA</h1>
          <p className="textoSobreNosotros">
            APROVECHANDO EL TIEMPO QUE NO PODEMOS DEDICAR A GESTIONAR TODO LO QUE CONLLEVA LA COMPRA DE NUESTRO SUEÑO,
            MÁS QUE NUNCA VEMOS LA IMPORTANCIA DE ADQUIRIR NUESTRO REFUGIO IDEAL.
            <br />
            <br />
            POR ELLO ESTAMOS A SU DISPOSICIÓN PARA AYUDARLES CON LAS GESTIONES HIPOTECARIAS Y ASESORAMIENTO. NUESTRO
            COMPROMISO DE ENCONTRAR LA VIVIENDA PERFECTA AL PRECIO MÁS COMPETITIVO DEL MERCADO ES NUESTRO LEMA.
          </p>
        </Container>
      </Jumbotron>
      <BannerInfo />
      <Footer />
    </div>
  );
}

export default GuestAboutMain;
