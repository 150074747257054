import React, { useState } from "react";

import "./CustomNotification.css";

import Toast from "react-bootstrap/Toast";
import Alert from "react-bootstrap/Alert";

const Notification = (props) => {
  function setShow(notification) {
    let copyNotifications = [...props.notifications];
    copyNotifications.map((current) => {
      if (notification.id === current.id) {
        notification.show = false;
      }
    });
    props.setNotifications(copyNotifications);
  }

  return (
    <div className="container-notifications">
      {props.notifications.map((current) => (
        <Toast
          key={current.id}
          onClose={() => setShow(current)}
          show={current.show}
          autohide
          animation
          delay={current.delay}
        >
          <Toast.Header>
            <Alert variant="danger" className="mr-auto">
              <strong>{current.typeName}</strong>
            </Alert>
          </Toast.Header>
          <Toast.Body>{current.message}</Toast.Body>
        </Toast>
      ))}
    </div>
  );
};

export default Notification;
