/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHouse = /* GraphQL */ `
  query GetHouse($id: ID!) {
    getHouse(id: $id) {
      id
      type
      reference
      title
      description
      surface
      price
      highlighted
      highlightedText
      mediaHighlighted
      zone
      city
      address
      showAddress
      housingEstate
      houseStatus
      houseType
      media
      bedrooms
      bathrooms
      floor
      door
      block
      publicationDate
      sold
      rent
      rented
      sale
      rentSaleOrRent
      reserved
      showAfterSoldOrRent
      builtYear
      builtArea
      usefulSurface
      surfacePlot
      northOrientation
      southOrientation
      eastOrientation
      westOrientation
      energyCertification
      updatedAt
      lift
      pool
      parking
      yard
      storageRoom
      builtClosets
      airConditioning
      terrace
      balcony
      individualHeating
      centralHeating
      heatingNotAvailable
      createdAt
    }
  }
`;
export const listHouses = /* GraphQL */ `
  query ListHouses(
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHouses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        reference
        title
        description
        surface
        price
        highlighted
        highlightedText
        mediaHighlighted
        zone
        city
        address
        showAddress
        housingEstate
        houseStatus
        houseType
        media
        bedrooms
        bathrooms
        floor
        door
        block
        publicationDate
        sold
        rent
        rented
        sale
        rentSaleOrRent
        reserved
        showAfterSoldOrRent
        builtYear
        builtArea
        usefulSurface
        surfacePlot
        northOrientation
        southOrientation
        eastOrientation
        westOrientation
        energyCertification
        updatedAt
        lift
        pool
        parking
        yard
        storageRoom
        builtClosets
        airConditioning
        terrace
        balcony
        individualHeating
        centralHeating
        heatingNotAvailable
        createdAt
      }
      nextToken
    }
  }
`;
export const getHouseType = /* GraphQL */ `
  query GetHouseType($id: ID!) {
    getHouseType(id: $id) {
      id
      uuid
      type
      createdAt
      updatedAt
    }
  }
`;
export const listHouseTypes = /* GraphQL */ `
  query ListHouseTypes(
    $filter: ModelHouseTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHouseTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uuid
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStatus = /* GraphQL */ `
  query GetStatus($id: ID!) {
    getStatus(id: $id) {
      id
      uuid
      status
      createdAt
      updatedAt
    }
  }
`;
export const listStatuss = /* GraphQL */ `
  query ListStatuss(
    $filter: ModelStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uuid
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const housesByModelType = /* GraphQL */ `
  query HousesByModelType(
    $type: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    housesByModelType(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        reference
        title
        description
        surface
        price
        highlighted
        highlightedText
        mediaHighlighted
        zone
        city
        address
        showAddress
        housingEstate
        houseStatus
        houseType
        media
        bedrooms
        bathrooms
        floor
        door
        block
        publicationDate
        sold
        rent
        rented
        sale
        rentSaleOrRent
        reserved
        showAfterSoldOrRent
        builtYear
        builtArea
        usefulSurface
        surfacePlot
        northOrientation
        southOrientation
        eastOrientation
        westOrientation
        energyCertification
        updatedAt
        lift
        pool
        parking
        yard
        storageRoom
        builtClosets
        airConditioning
        terrace
        balcony
        individualHeating
        centralHeating
        heatingNotAvailable
        createdAt
      }
      nextToken
    }
  }
`;
export const houseByReference = /* GraphQL */ `
  query HouseByReference(
    $reference: String
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    houseByReference(
      reference: $reference
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        reference
        title
        description
        surface
        price
        highlighted
        highlightedText
        mediaHighlighted
        zone
        city
        address
        showAddress
        housingEstate
        houseStatus
        houseType
        media
        bedrooms
        bathrooms
        floor
        door
        block
        publicationDate
        sold
        rent
        rented
        sale
        rentSaleOrRent
        reserved
        showAfterSoldOrRent
        builtYear
        builtArea
        usefulSurface
        surfacePlot
        northOrientation
        southOrientation
        eastOrientation
        westOrientation
        energyCertification
        updatedAt
        lift
        pool
        parking
        yard
        storageRoom
        builtClosets
        airConditioning
        terrace
        balcony
        individualHeating
        centralHeating
        heatingNotAvailable
        createdAt
      }
      nextToken
    }
  }
`;
export const listLastUpdates = /* GraphQL */ `
  query ListLastUpdates(
    $updatedAt: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLastUpdates(
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        reference
        title
        description
        surface
        price
        highlighted
        highlightedText
        mediaHighlighted
        zone
        city
        address
        showAddress
        housingEstate
        houseStatus
        houseType
        media
        bedrooms
        bathrooms
        floor
        door
        block
        publicationDate
        sold
        rent
        rented
        sale
        rentSaleOrRent
        reserved
        showAfterSoldOrRent
        builtYear
        builtArea
        usefulSurface
        surfacePlot
        northOrientation
        southOrientation
        eastOrientation
        westOrientation
        energyCertification
        updatedAt
        lift
        pool
        parking
        yard
        storageRoom
        builtClosets
        airConditioning
        terrace
        balcony
        individualHeating
        centralHeating
        heatingNotAvailable
        createdAt
      }
      nextToken
    }
  }
`;
