/** Queries custom */
export const getHighlighted = `query getHighlighted {
  housesByModelType(filter: {highlighted: {eq: true}, showAfterSoldOrRent: {eq: true}}, sortDirection: DESC, type: "HOUSE") {
    items {
      highlighted
      highlightedText
      reference
      media
      mediaHighlighted
      title
    }
  }
}
`;

export const houseByReference = /* GraphQL */ `
  query houseByReference($reference: String) {
    houseByReference(reference: $reference) {
      items {
        media
        reference
        title
        houseType
        zone
        city
        showAddress
        address
        block
        floor
        door
        price
        sale
        reserved
        rent
        rented
        sold
        rentSaleOrRent
        houseStatus
        surface
        bedrooms
        bathrooms
        highlightedText
        description
        airConditioning
        balcony
        builtClosets
        lift
        parking
        pool
        storageRoom
        terrace
        yard
        centralHeating
        individualHeating
        heatingNotAvailable
        energyCertification
        builtYear
        surfacePlot
        usefulSurface
        northOrientation
        eastOrientation
        southOrientation
        westOrientation
      }
    }
  }
`;

export const listCities = `query listCities {
  listHouses {
    items {
      city
    }
  }
}`;

export const defaultListHouses = `query defaultListHouses {
  housesByModelType(sortDirection: DESC, type: "HOUSE", limit: 10, filter: {showAfterSoldOrRent: {eq: true}}) {
    items {
      type
      updatedAt
      reference
      media
      title
      price
      surface
      highlightedText
      description
      bedrooms
    }
    nextToken
  }
}

`;

export const filteredListHouses = `query filteredListHouses($filter: ModelHouseFilterInput = null, $nextToken: String = null) {
  housesByModelType(filter: $filter, nextToken: $nextToken, type: "HOUSE", sortDirection: DESC, limit: 8) {
    nextToken
    items {
      type
      updatedAt
      reference
      media
      title
      price
      surface
      highlightedText
      description
      bedrooms
    }
    nextToken
  }
}
`;

export const filteredListHousesAdmin = `query filteredListHousesAdmin($nextToken: String = null, $filter: ModelHouseFilterInput = null) {
  housesByModelType(filter: $filter, type: "HOUSE", sortDirection: DESC, nextToken: $nextToken, limit: 7) {
    items {
      id
      reference
      title
      price
      surface
      city
      showAfterSoldOrRent
      rent
      sale
      rented
    }
    nextToken
  }
}`;

export const allDataFromReference = `query allDataFromReference($reference: String = "EMPTY") {
  houseByReference(reference: $reference) {
    items {
      address
      airConditioning
      balcony
      bathrooms
      bedrooms
      block
      builtArea
      builtClosets
      builtYear
      centralHeating
      city
      createdAt
      description
      door
      eastOrientation
      energyCertification
      floor
      heatingNotAvailable
      highlighted
      highlightedText
      houseStatus
      houseType
      housingEstate
      id
      individualHeating
      lift
      media
      id
      zone
      yard
      westOrientation
      usefulSurface
      updatedAt
      type
      title
      terrace
      surfacePlot
      surface
      storageRoom
      southOrientation
      sold
      showAfterSoldOrRent
      showAddress
      sale
      reserved
      rentSaleOrRent
      rent
      rented
      reference
      publicationDate
      pool
      price
      parking
      northOrientation
      mediaHighlighted
    }
  }
}
`;
