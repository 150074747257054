import React from "react";

import "./GuestSearchForm.css";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
 
import { useForm } from "react-hook-form";


function GuestSearchForm({
  commonState,
  cities,
  houseStatus,
  houseTypes,
  onChange,
  searching,
  clearFilter,
  addCommas,
  removeCommas,
}) {
  const { register, errors, handleSubmit } = useForm();

  function localOnChange(e) {
    onChange(e);
  }

  return (
    <>
      <Container fluid className="container-search">
        <Form onSubmit={handleSubmit(searching)}>
          {/* Select de tipo de inmueble */}
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label>Tipo de inmueble</Form.Label>
              <Form.Control
                as="select"
                id="typeHouse"
                name="typeHouse"
                multiple
                onChange={onChange}
                value={commonState.typeHouse}
              >
                <option value="0">Todos</option>
                {houseTypes.map((value) => (
                  <option key={value.id} id={value.id} value={value.id}>
                    {value.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          {/* Select de estado de inmueble */}
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label>Estado inmueble</Form.Label>
              <Form.Control
                as="select"
                id="houseStatus"
                name="houseStatus"
                multiple
                onChange={onChange}
                value={commonState.houseStatus}
              >
                <option value="0">Todos</option>
                {houseStatus.map((value) => (
                  <option key={value.id} id={value.id} value={value.id}>
                    {value.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          {/* Select zona de inmueble */}
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label>Zona</Form.Label>
              <Form.Control as="select" id="zone" name="zone" multiple onChange={onChange} value={commonState.zone}>
                <option value="0">Todas</option>
                {cities.map((value) => (
                  <option key={value.id} id={value.id} value={value.id}>
                    {value.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          {/* Tipo de operacion */}
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label as="label">Operación</Form.Label>
              <Form.Check
                type="radio"
                label="En Alquiler"
                name="radioRentSaleOrRent"
                value="radioRent"
                id="radioRent"
                onChange={onChange}
                value={commonState.radioRent}
                checked={commonState.radioRent}
              />
              <Form.Check
                type="radio"
                label="En Venta"
                name="radioRentSaleOrRent"
                value="radioSale"
                id="radioSale"
                onChange={onChange}
                value={commonState.radioSale}
                checked={commonState.radioSale}
              />
              <Form.Check
                type="radio"
                label="Alquiler con opción a compra"
                name="radioRentSaleOrRent"
                value="radioRentSale"
                id="radioRentSale"
                onChange={localOnChange}
                value={commonState.radioRentSale}
                checked={commonState.radioRentSale}
              />
            </Form.Group>
          </Form.Row>
          {/* Precio */}
          <Form.Row>
            <Form.Label>Precio</Form.Label>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6">
              {/* <Form.Label>Precio</Form.Label> */}
              <Form.Control
                id="minPrice"
                name="minPrice"
                type="number"
                placeholder="Sin mínimo"
                onChange={localOnChange}
                onClick={removeCommas}
                onBlur={addCommas}
                value={commonState.minPrice}
              />
              {/* <Form.Control
                as="select"
                id="minPrice"
                name="minPrice"
                onChange={localOnChange}
                value={commonState.minPrice}
              >
                <option value="0">Sin mínimo</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="800">800</option>
                <option value="1000">1.000</option>
                <option value="2000">2.000</option>
                <option value="50000">50.000</option>
                <option value="250000">250.000</option>
                <option value="500000">500.000</option>
                <option value="900000">900.000</option>
                ))}
              </Form.Control> */}
            </Form.Group>
            <Form.Group as={Col} md="6">
              {/* <Form.Label>Precio</Form.Label> */}
              <Form.Control
                id="maxPrice"
                name="maxPrice"
                type="number"
                placeholder="Sin limite"
                onChange={localOnChange}
                onClick={removeCommas}
                onBlur={addCommas}
                value={commonState.maxPrice}
              />
              {/* <Form.Control
                as="select"
                id="maxPrice"
                name="maxPrice"
                onChange={localOnChange}
                value={commonState.maxPrice}
              >
                <option value="0">Sin limite</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="800">800</option>
                <option value="1000">1.000</option>
                <option value="2000">2.000</option>
                <option value="50000">50.000</option>
                <option value="250000">250.000</option>
                <option value="500000">500.000</option>
                <option value="900000">900.000</option>
                ))}
              </Form.Control> */}
            </Form.Group>
          </Form.Row>
          {/* Tamaño */}
          <Form.Row>
            <Form.Label>
              Superficie m<sup>2</sup>
            </Form.Label>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6">
              <Form.Control
                id="minSurface"
                name="minSurface"
                type="number"
                placeholder="Sin mínimo"
                onChange={localOnChange}
                value={commonState.minSurface}
              />
              {/* <Form.Control
                as="select"
                id="minSurface"
                name="minSurface"
                onChange={localOnChange}
                value={commonState.minSurface}
              >
                <option value="0">Sin mínimo</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
                <option value="600">600</option>
                <option value="700">700</option>
                ))}
              </Form.Control> */}
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Control
                id="maxSurface"
                name="maxSurface"
                type="number"
                placeholder="Sin limite"
                onChange={localOnChange}
                value={commonState.maxSurface}
              />
              {/* <Form.Control
                as="select"
                id="maxSurface"
                name="maxSurface"
                onChange={localOnChange}
                value={commonState.maxSurface}
              >
                <option value="0">Sin limite</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
                <option value="600">600</option>
                <option value="700">700</option>
                ))}
              </Form.Control> */}
            </Form.Group>
          </Form.Row>
          {/* Habitaciones */}
          <Form.Row>
            <Form.Group as={Col} md="8">
              <Form.Label>Habitaciones</Form.Label>
              <Form.Control
                as="select"
                id="nBedrooms"
                name="nBedrooms"
                multiple
                onChange={localOnChange}
                value={commonState.nBedrooms}
              >
                <option value="0">Sin determinar</option>
                <option value="1">0</option>
                <option value="2">1</option>
                <option value="3">2</option>
                <option value="4">3 o más</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label as="label">Caracteristicas</Form.Label>
              <Form.Check
                id="lift"
                type="switch"
                label="Ascensor"
                onChange={localOnChange}
                value={commonState.lift}
                checked={commonState.lift}
              />
              <Form.Check
                id="pool"
                type="switch"
                label="Piscina"
                onChange={localOnChange}
                value={commonState.pool}
                checked={commonState.pool}
              />
              <Form.Check
                id="parking"
                type="switch"
                label="Plaza de parking"
                onChange={localOnChange}
                value={commonState.parking}
                checked={commonState.parking}
              />
              <Form.Check
                id="yard"
                type="switch"
                label="Patio"
                onChange={localOnChange}
                value={commonState.yard}
                checked={commonState.yard}
              />
              <Form.Check
                id="storageroom"
                type="switch"
                label="Trastero"
                onChange={localOnChange}
                value={commonState.storageroom}
                checked={commonState.storageroom}
              />
              <Form.Check
                id="builtClosets"
                type="switch"
                label="Armarios empotrados"
                onChange={localOnChange}
                value={commonState.builtClosets}
                checked={commonState.builtClosets}
              />
              <Form.Check
                id="airConditioning"
                type="switch"
                label="Aire acondicionado"
                onChange={localOnChange}
                value={commonState.airConditioning}
                checked={commonState.airConditioning}
              />
              <Form.Check
                id="terrace"
                type="switch"
                label="Terraza"
                onChange={localOnChange}
                value={commonState.terrace}
                checked={commonState.terrace}
              />
              <Form.Check
                id="balcony"
                type="switch"
                label="Balcón"
                onChange={localOnChange}
                value={commonState.balcony}
                checked={commonState.balcony}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Button type="submit" variant="info" block className="boton-filtros">
              Aplicar filtros
            </Button>
          </Form.Row>
          <Form.Row>
            <Button type="buton" variant="outline-info" block onClick={clearFilter} className="boton-filtros">
              Limpiar filtros
            </Button>
          </Form.Row>
        </Form>
      </Container>
    </>
  );
}

export default GuestSearchForm;
