import React from "react";

import "./ActionModal.css";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { API } from "aws-amplify";
import { updateHouse as updateHouseMutation } from "../../graphql/mutations";
import { Container, Row, Col } from "react-bootstrap";

function ActionModal(props) {
  function sold() {
    let inputHouse = {
      id: props.id,
      sold: true,
    };
    updatedEstate(inputHouse);
  }

  function unsold() {
    let inputHouse = {
      id: props.id,
      sold: false,
    };
    updatedEstate(inputHouse);
  }

  function reserved() {
    let inputHouse = {
      id: props.id,
      reserved: true,
    };
    updatedEstate(inputHouse);
  }

  function unreserved() {
    let inputHouse = {
      id: props.id,
      reserved: false,
    };
    updatedEstate(inputHouse);
  }

  function archived() {
    let inputHouse = {
      id: props.id,
      showAfterSoldOrRent: false,
    };
    updatedEstate(inputHouse);
  }

  function unarchived() {
    let inputHouse = {
      id: props.id,
      showAfterSoldOrRent: true,
    };
    updatedEstate(inputHouse);
  }

  function rent() {
    let inputHouse = {
      id: props.id,
      rented: true,
    };
    updatedEstate(inputHouse);
    updatedEstate(inputHouse);
  }

  function unrent() {
    let inputHouse = {
      id: props.id,
      rented: false,
    };
    updatedEstate(inputHouse);
  }

  async function updatedEstate(inputHouse) {
    let updated = await API.graphql({ query: updateHouseMutation, variables: { input: inputHouse } }).catch((error) => {
      console.log("Error al actualizar la propiedad: {}", JSON.stringify(error));
    });

    if (updated) {
      props.onHide();
    }
  }

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Propiedad: #{props.reference}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <br />
              <h4>
                Vas a marcar la propiedad #<strong>{props.reference}</strong>
              </h4>
              <br />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <br />
              <h4>
                Como <strong>Vendida/No Vendida</strong>.
              </h4>
              <br />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="success" block onClick={sold}>
                VENDIDA
              </Button>
            </Col>
            <Col>
              <Button variant="success" block onClick={unsold}>
                NO VENDIDA
              </Button>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <br />
              <h4>
                Como <strong>Reservada/ No Reservada</strong>.
              </h4>
              <br />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="light" block onClick={reserved}>
                RESERVADA
              </Button>
            </Col>
            <Col>
              <Button variant="light" block onClick={unreserved}>
                NO RESERVADA
              </Button>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <br />
              <h4>
                Como <strong>Alquilada/ No alquilada</strong>.
              </h4>
              <br />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="info" block onClick={rent}>
                ALQUILADA
              </Button>
            </Col>
            <Col>
              <Button variant="info" block onClick={unrent}>
                NO ALQUILADA
              </Button>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <br />
              <h4>
                Como <strong>Archivada/No Archivada</strong> (no se mostrara en el buscador del cliente)
              </h4>
              <br />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="warning" block onClick={archived}>
                ARCHIVAR
              </Button>
            </Col>
            <Col>
              <Button variant="warning" block onClick={unarchived}>
                DESARCHIVAR
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ActionModal;
