import React, { useState, useEffect } from "react";
import "./AdminLogin.css";
import AdminMain from "../admin-main/AdminMain";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import { Auth, Hub } from "aws-amplify";

const initialFormState = {
  username: "",
  password: "",
  email: "",
  authCode: "",
  formType: "signedIn",
};

function AdminLogin() {
  const [user, updateUser] = useState(null);
  const [isLoggin, setIsLoggin] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [formState, updateFormState] = useState(initialFormState);
  const { formType } = formState;
  useEffect(() => {
    checkUser();
    setAuthListener();
  }, []);

  async function setAuthListener() {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          console.log("user signed in");
          break;
        case "signOut":
          console.log("user signed out");
          updateFormState(() => ({ ...formState, formType: "signIn" }));
          break;
        case "signIn_failure":
          console.log("user sign in failed");
          setErrorLogin(true);
          setIsLoggin(false);
          setErrorMsg("Error de acceso, comprueba tu usuario o contraseña");
          break;
        default:
          break;
      }
    });
  }
  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      updateUser(user);
      updateFormState(() => ({ ...formState, formType: "signedIn" }));
    } catch (error) {
      setIsLoggin(false);
      console.log("CheckUser ERROR: ", error);
      updateFormState(() => ({ ...formState, formType: "signIn" }));
    }
  }
  function onChange(e) {
    setErrorLogin(false);
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.id]: e.target.value }));
  }

  async function signIn(event) {
    const { username, password } = formState;
    const form = event.currentTarget;
    setIsLoggin(true);
    setErrorLogin(false);
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      await Auth.signIn(username, password);
      updateFormState(() => ({ ...formState, formType: "signedIn" }));
    }
    setValidated(true);
    setIsLoggin(false);
  }

  return (
    <Container fluid className="padding-login">
      {formType === "signIn" && (
        <div className="App">
          <div id="img-background" />
          <div id="admin-container">
            <Container>
              <Row className="justify-content-md-center">
                <Col md={6} lg={6} sm={12}>
                  <div>
                    <Row>
                      <Col md={{ span: 3, offset: 2 }} lg={{ span: 3, offset: 3 }} sm={{ span: 3, offset: 3 }}>
                        <div id="divLogo"></div>
                      </Col>
                    </Row>

                    <Form noValidate validated={validated} onSubmit={signIn} className="loginForm">
                      <Form.Group as={Row} controlId="username">
                        <Form.Label column md={3} lg={3}>
                          Usuario
                        </Form.Label>
                        <Col md={9} lg={9}>
                          <Form.Control required type="text" onChange={onChange} placeholder="usuario" />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="password">
                        <Form.Label column md={3} lg={3}>
                          Contraseña
                        </Form.Label>
                        <Col md={9} lg={9}>
                          <Form.Control required type="password" onChange={onChange} placeholder="Contraseña" />
                        </Col>
                      </Form.Group>
                      <Row>
                        <Col md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} sm={{ span: 4, offset: 4 }}>
                          {!isLoggin && <Button type="submit">Iniciar sesión</Button>}
                          {isLoggin && (
                            <Button type="button" disabled>
                              <Row>
                                <Col md={3} lg={3} sm={3}>
                                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                </Col>
                                <Col md={9} lg={9} sm={9}>
                                  Cargando...
                                </Col>
                              </Row>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                    <Row id="errorRow">
                      {errorLogin && (
                        <Col id="errorCol" md={12} lg={12}>
                          <Alert
                            key="error01"
                            variant="danger"
                            transition="true"
                            onClose={() => setErrorLogin(false)}
                            dismissible
                          >
                            {errorMsg}
                          </Alert>
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
      {formType === "signedIn" && <AdminMain />}
    </Container>
  );
}

export default AdminLogin;
