import React, { useState, useEffect } from "react";

import "./PreviewModalImage.css";

import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";

const PreviewModalImage = (props) => {
  const [loading, setLoading] = useState(true);
  const [picture, setPicture] = useState();
  const [modalProps, setModalProps] = useState(() => {
    return { onHide: props.onHide, picture: props.picture, show: props.show };
  });

  useEffect(() => {
    onLoadSuccesful();
  }, []);

  async function onLoadSuccesful() {
    if (props.isUpdated) {
      setPicture(props.picture);
    } else {
      setPicture(URL.createObjectURL(props.picture));
    }
    await new Promise((resolve) => setTimeout(resolve, 700));
    setLoading(false);
  }

  return (
    <Modal {...modalProps} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      {!props.isUpdated && (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{props.picture.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="body-modal-pictures">
            {loading && <Spinner animation="border" variant="secondary" />}
            {loading === false && props.picture.type.startsWith("image/") && <Image thumbnail src={picture} fluid />}
            {loading === false && props.picture.type.startsWith("video/") && (
              <ReactPlayer url={picture} controls light width="100%" />
            )}
          </Modal.Body>
        </>
      )}
      {props.isUpdated && (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{props.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="body-modal-pictures">
            {loading && <Spinner animation="border" variant="secondary" />}
            {loading === false && !props.id.startsWith("video") && <Image thumbnail src={picture} fluid />}
            {loading === false && props.id.startsWith("video") && (
              <ReactPlayer url={picture} controls light width="100%" />
            )}
          </Modal.Body>
        </>
      )}

      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewModalImage;
