/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHouse = /* GraphQL */ `
  mutation CreateHouse(
    $input: CreateHouseInput!
    $condition: ModelHouseConditionInput
  ) {
    createHouse(input: $input, condition: $condition) {
      id
      type
      reference
      title
      description
      surface
      price
      highlighted
      highlightedText
      mediaHighlighted
      zone
      city
      address
      showAddress
      housingEstate
      houseStatus
      houseType
      media
      bedrooms
      bathrooms
      floor
      door
      block
      publicationDate
      sold
      rent
      rented
      sale
      rentSaleOrRent
      reserved
      showAfterSoldOrRent
      builtYear
      builtArea
      usefulSurface
      surfacePlot
      northOrientation
      southOrientation
      eastOrientation
      westOrientation
      energyCertification
      updatedAt
      lift
      pool
      parking
      yard
      storageRoom
      builtClosets
      airConditioning
      terrace
      balcony
      individualHeating
      centralHeating
      heatingNotAvailable
      createdAt
    }
  }
`;
export const updateHouse = /* GraphQL */ `
  mutation UpdateHouse(
    $input: UpdateHouseInput!
    $condition: ModelHouseConditionInput
  ) {
    updateHouse(input: $input, condition: $condition) {
      id
      type
      reference
      title
      description
      surface
      price
      highlighted
      highlightedText
      mediaHighlighted
      zone
      city
      address
      showAddress
      housingEstate
      houseStatus
      houseType
      media
      bedrooms
      bathrooms
      floor
      door
      block
      publicationDate
      sold
      rent
      rented
      sale
      rentSaleOrRent
      reserved
      showAfterSoldOrRent
      builtYear
      builtArea
      usefulSurface
      surfacePlot
      northOrientation
      southOrientation
      eastOrientation
      westOrientation
      energyCertification
      updatedAt
      lift
      pool
      parking
      yard
      storageRoom
      builtClosets
      airConditioning
      terrace
      balcony
      individualHeating
      centralHeating
      heatingNotAvailable
      createdAt
    }
  }
`;
export const deleteHouse = /* GraphQL */ `
  mutation DeleteHouse(
    $input: DeleteHouseInput!
    $condition: ModelHouseConditionInput
  ) {
    deleteHouse(input: $input, condition: $condition) {
      id
      type
      reference
      title
      description
      surface
      price
      highlighted
      highlightedText
      mediaHighlighted
      zone
      city
      address
      showAddress
      housingEstate
      houseStatus
      houseType
      media
      bedrooms
      bathrooms
      floor
      door
      block
      publicationDate
      sold
      rent
      rented
      sale
      rentSaleOrRent
      reserved
      showAfterSoldOrRent
      builtYear
      builtArea
      usefulSurface
      surfacePlot
      northOrientation
      southOrientation
      eastOrientation
      westOrientation
      energyCertification
      updatedAt
      lift
      pool
      parking
      yard
      storageRoom
      builtClosets
      airConditioning
      terrace
      balcony
      individualHeating
      centralHeating
      heatingNotAvailable
      createdAt
    }
  }
`;
export const createHouseType = /* GraphQL */ `
  mutation CreateHouseType(
    $input: CreateHouseTypeInput!
    $condition: ModelHouseTypeConditionInput
  ) {
    createHouseType(input: $input, condition: $condition) {
      id
      uuid
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateHouseType = /* GraphQL */ `
  mutation UpdateHouseType(
    $input: UpdateHouseTypeInput!
    $condition: ModelHouseTypeConditionInput
  ) {
    updateHouseType(input: $input, condition: $condition) {
      id
      uuid
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteHouseType = /* GraphQL */ `
  mutation DeleteHouseType(
    $input: DeleteHouseTypeInput!
    $condition: ModelHouseTypeConditionInput
  ) {
    deleteHouseType(input: $input, condition: $condition) {
      id
      uuid
      type
      createdAt
      updatedAt
    }
  }
`;
export const createStatus = /* GraphQL */ `
  mutation CreateStatus(
    $input: CreateStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    createStatus(input: $input, condition: $condition) {
      id
      uuid
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateStatus = /* GraphQL */ `
  mutation UpdateStatus(
    $input: UpdateStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    updateStatus(input: $input, condition: $condition) {
      id
      uuid
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteStatus = /* GraphQL */ `
  mutation DeleteStatus(
    $input: DeleteStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    deleteStatus(input: $input, condition: $condition) {
      id
      uuid
      status
      createdAt
      updatedAt
    }
  }
`;
