import React, { useState, useEffect } from "react";

import "./AdminListHouses.css";
import { API } from "aws-amplify";

import { filteredListHousesAdmin } from "../graphql/custom-queries";

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import Button from "react-bootstrap/Button";

import { Cash } from "react-bootstrap-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import InfiniteScroll from "react-infinite-scroll-component";

import ActionModal from "./action-modal/ActionModal";

function AdminListHouses({ currentFilter, setHouseSelected }) {
  const [houses, setHouses] = useState([]);
  const [nextStateToken, setNextStateToken] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalId, setModalId] = useState("");
  const [reference, setReference] = useState("");

  useEffect(() => {
    fetchHousesResetList();
  }, [currentFilter]);

  async function callHouses(tokenToCall) {
    const apiData = await API.graphql({
      query: filteredListHousesAdmin,
      variables: { filter: currentFilter, nextToken: tokenToCall },
    });
    if (apiData.data.housesByModelType.items.length < 7 && apiData.data.housesByModelType.nextToken) {
      let currentHouses = await callHouses(apiData.data.housesByModelType.nextToken);
      if (apiData.data.housesByModelType.items.length === 0) {
        apiData.data.housesByModelType.items = currentHouses.data.housesByModelType.items;
      } else {
        apiData.data.housesByModelType.items.concat(currentHouses.data.housesByModelType.items);
      }
      apiData.data.housesByModelType.nextToken = currentHouses.data.housesByModelType.nextToken;
    }
    return apiData;
  }

  async function fetchHouses() {
    const apiData = await callHouses(nextStateToken);
    setNextStateToken(apiData.data.housesByModelType.nextToken);
    setHouses([...houses].concat(apiData.data.housesByModelType.items));
  }

  async function fetchHousesResetList() {
    const apiData = await callHouses(null);
    setNextStateToken(apiData.data.housesByModelType.nextToken);
    setHouses(apiData.data.housesByModelType.items);
  }

  function buildCommas(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2 + ",00";
  }

  return (
    <Container fluid className="rows-admin-list-2">
      <Row>
        <Col>Mostrando {houses.length} resultados</Col>
      </Row>
      <InfiniteScroll
        dataLength={houses.length}
        next={fetchHouses}
        hasMore={nextStateToken !== "" && nextStateToken !== null}
        height={800}
        loader={
          <h5>
            Loading <Spinner animation="grow" variant="dark" size="sm" />{" "}
            <Spinner animation="grow" variant="dark" size="sm" /> <Spinner animation="grow" variant="dark" size="sm" />
          </h5>
        }
        endMessage={
          <Table striped bordered hover responsive>
            <tbody>
              <tr>
                <td align="center" colSpan="6">
                  <b>No hay mas resultados</b>
                </td>
              </tr>
            </tbody>
          </Table>
        }
      >
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#Ref</th>
              <th>Nombre</th>
              <th>Precio</th>
              <th>Superficie</th>
              <th>Ciudad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {houses !== [] &&
              houses.map((currentHouse) => (
                <tr key={currentHouse.reference}>
                  <th>#{currentHouse.reference}</th>
                  <th>
                    {currentHouse.title}
                    <br />
                    {!currentHouse.showAfterSoldOrRent && (
                      <Button variant="warning" size="sm" disabled>
                        ARCHIVADA
                      </Button>
                    )}{" "}
                    {currentHouse.reserved && (
                      <Button variant="ligth" size="sm" disabled>
                        RESERVADA
                      </Button>
                    )}{" "}
                    {currentHouse.sold && (
                      <Button variant="success" size="sm" disabled>
                        VENDIDA
                      </Button>
                    )}{" "}
                    {currentHouse.rented && (
                      <Button variant="info" size="sm" disabled>
                        ALQUILADA
                      </Button>
                    )}
                  </th>
                  <th>{buildCommas(currentHouse.price)} €</th>
                  <th>{currentHouse.surface} m2</th>
                  <th>{currentHouse.city}</th>
                  <th>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        setHouseSelected(currentHouse.reference);
                      }}
                      block
                    >
                      <FontAwesomeIcon icon={faEdit} pull="left" /> Editar
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => {
                        setModalShow(true);
                        setModalId(currentHouse.id);
                        setReference(currentHouse.reference);
                      }}
                      block
                    >
                      <Cash />
                    </Button>
                  </th>
                </tr>
              ))}
          </tbody>
        </Table>
      </InfiniteScroll>
      <ActionModal
        show={modalShow}
        id={modalId}
        reference={reference}
        onHide={() => {
          setModalId("");
          setReference("");
          setModalShow(false);
          fetchHousesResetList();
        }}
      />
    </Container>
  );
}

export default AdminListHouses;
