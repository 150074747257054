import React, { useState, useEffect } from "react";

import "./ListImages.css";

import Figure from "react-bootstrap/Figure";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { faGem, faTrashAlt } from "@fortawesome/free-regular-svg-icons";

import PreviewModalImage from "../PreviewImage/PreviewModalImage";

const ListImages = (props) => {
  const [media, setMedia] = useState([]);
  const [mediaRemove, setMediaRemove] = useState([]);

  useEffect(() => {
    setMedia(props.media);
  }, [props]);

  function tooglePictureModal(idPicture) {
    let copyState = [...media];
    copyState.map((current) => {
      if (idPicture === current.id) {
        current.displayModal = !current.displayModal;
      }
    });
    setMedia(copyState);
  }

  function onChange(e) {
    e.persist();
    let copyMediaRemove = [...mediaRemove];
    let mediFiltered = [];
    if (e.target.type === "radio") {
      props.setHighlightedImage(e.target.value);
    } else if (e.target.type === "checkbox") {
      if (e.target.checked) {
        copyMediaRemove.push(e.target.id);
        setMediaRemove(copyMediaRemove);
        props.setMediaRemove(copyMediaRemove);
      } else {
        mediFiltered = copyMediaRemove.filter(function (item) {
          return item !== e.target.id;
        });
        setMediaRemove(mediFiltered);
        props.setMediaRemove(mediFiltered);
      }
    }
  }

  return (
    <Container fluid className="cointainer-images">
      <Row className="row-images">
        {media.map((current) => (
          <div key={current.id} className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <Container fluid className="miniature">
              <Row className="justify-content row-trash">
                <Col className="text-right col-trash">
                  <Form.Group>
                    <Form.Check
                      type="switch"
                      label={<FontAwesomeIcon icon={faTrashAlt} />}
                      id={current.id}
                      name="action-media"
                      onChange={onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center row-miniature">
                <Figure>
                  {!current.isUpdated && (
                    <>
                      {current.picture.type.startsWith("video/") && (
                        <div className="justify-content container-video-miniature">
                          <div className="span6" className="container-video">
                            <FontAwesomeIcon
                              icon={faPlayCircle}
                              size="6x"
                              onClick={() => tooglePictureModal(current.id)}
                            />
                            <p>{current.picture.name}</p>
                          </div>
                        </div>
                      )}
                      {current.picture.type.startsWith("image/") && (
                        <div>
                          <Figure.Image
                            thumbnail
                            alt={current.picture.name}
                            src={URL.createObjectURL(current.picture)}
                            id={current.id}
                            onClick={() => tooglePictureModal(current.id)}
                          />
                          <Figure.Caption className="picture-name">{current.picture.name}</Figure.Caption>
                        </div>
                      )}
                    </>
                  )}

                  {current.isUpdated && (
                    <>
                      {current.id.startsWith("video-") && (
                        <div className="justify-content container-video-miniature">
                          <div className="span6" className="container-video">
                            <FontAwesomeIcon
                              icon={faPlayCircle}
                              size="6x"
                              onClick={() => tooglePictureModal(current.id)}
                            />
                            <p>{current.picture.name}</p>
                          </div>
                        </div>
                      )}
                      {!current.id.startsWith("video-") && (
                        <div>
                          <Figure.Image
                            thumbnail
                            alt={current.id}
                            src={current.picture}
                            id={current.id}
                            onClick={() => tooglePictureModal(current.id)}
                          />
                          <Figure.Caption className="picture-name">{current.id}</Figure.Caption>
                        </div>
                      )}
                    </>
                  )}

                  {current.displayModal === true && (
                    <PreviewModalImage
                      id={current.id}
                      picture={current.picture}
                      show={current.displayModal}
                      isUpdated={current.isUpdated}
                      onHide={() => tooglePictureModal(current.id)}
                    />
                  )}
                </Figure>
              </Row>
              <Row className="justify-content-center row-miniature">
                {!current.isUpdated && (
                  <>
                    {current.picture.type.startsWith("image/") && (
                      <ButtonGroup toggle>
                        <ToggleButton
                          disabled={!current.highlightedActive}
                          type="radio"
                          variant="info"
                          name="action-media"
                          value={current.id}
                          onChange={onChange}
                        >
                          <FontAwesomeIcon icon={faGem} />
                        </ToggleButton>
                      </ButtonGroup>
                    )}
                    {current.picture.type.startsWith("video/") && (
                      <ButtonGroup toggle>
                        <ToggleButton disabled type="radio" variant="info" name="action-media-video" value={current.id}>
                          <FontAwesomeIcon icon={faGem} />
                        </ToggleButton>
                      </ButtonGroup>
                    )}
                  </>
                )}

                {current.isUpdated && (
                  <>
                    {!current.id.startsWith("video-") && (
                      <ButtonGroup toggle>
                        <ToggleButton
                          disabled={!current.highlightedActive}
                          type="radio"
                          variant="info"
                          name="action-media"
                          value={current.id}
                          onChange={onChange}
                        >
                          <FontAwesomeIcon icon={faGem} />
                        </ToggleButton>
                      </ButtonGroup>
                    )}
                    {current.id.startsWith("video-") && (
                      <ButtonGroup toggle>
                        <ToggleButton
                          disabled={true}
                          type="radio"
                          variant="info"
                          name="action-media-video"
                          value={current.id}
                        >
                          <FontAwesomeIcon icon={faGem} />
                        </ToggleButton>
                      </ButtonGroup>
                    )}
                  </>
                )}
              </Row>
            </Container>
          </div>
        ))}
      </Row>
    </Container>
  );
};

export default ListImages;
