import React from "react";
import "./GuestPrivacidad.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GuestNavbar from "../guest-navbar/GuestNavbar";
import Footer from "../footer/footer";
import BannerInfo from "../guest-banner-index/GuestBannerIndex";
import imagenPrivacidad from "../../img/PoliticaPrivacidad.png";

function GuestPrivacidad() {
  return (
    <div>
      <GuestNavbar />
      <Container fluid className="fondo-privacidad">
        <img src={imagenPrivacidad} className="imagen-politica-privacidad" alt="imagen-politica-privacidad" />
        <Row className="container-background-privacidad"></Row>
      </Container>

      <Container className="container-privacidad">
        <h2>PRIVACIDAD</h2>
        <br />
        <h3>Responsable – ¿Quién es el responsable del tratamiento de los datos?</h3>
        <p>Identidad: M.J MURCIA HERNÁNDEZ</p>
        <p>Correo Electrónico: info@harunnainvestments.com</p>
        <p>
          Nombre del dominio: <a href="https://www.harunnainvestments.com/">https://www.harunnainvestments.com/</a>{" "}
        </p>
        <h3>Finalidades – ¿Con qué finalidades tratamos tus datos?</h3>
        <p>
          En cumplimiento de lo dispuesto en el Reglamento Europeo 2016/679 General de Protección de Datos, te
          informamos de que trataremos los datos que nos facilitas para:
          <ul className="lista-privacidad">
            <li>
              Gestionar la contratación de servicios que realice a través de la Plataforma, así como la facturación y
              entrega correspondiente.
            </li>
            <li>
              Remitir periódicamente comunicaciones sobre servicios, eventos y noticias relacionadas con las actividades
              desarrolladas por HARUNNA INVESTMENTS, por cualquier medio (teléfono, correo postal o email), salvo que se
              indique lo contrario o el usuario se oponga o revoque su consentimiento.
            </li>
            <li>
              Remitir información comercial y / o promocional relacionada con el sector de servicios contratados y valor
              añadido para usuarios finales, salvo que se indique lo contrario o el usuario se oponga o revoque su
              consentimiento.
            </li>
            <li>
              Dar cumplimiento a las obligaciones legalmente establecidas, así como verificar el cumplimiento de las
              obligaciones contractuales, incluía la prevención de fraude.
            </li>
            <li>
              Cesión de datos a organismos y autoridades, siempre y cuando sean requeridos de conformidad con las
              disposiciones legales y reglamentarias.
            </li>
          </ul>
        </p>
        <h3>Categorías de datos – ¿Qué datos tratamos?</h3>
        <p>
          Derivada de las finalidades antes mencionadas, en HARUNNA INVESTMENTS gestionamos las siguientes categorías de
          datos:
          <ul className="lista-privacidad">
            <li>Datos identificativos.</li>
            <li>Metadatos de comunicaciones electrónicas.</li>
            <li>
              Datos de información comercial. En caso de que el usuario facilite datos de terceros, manifiesta contar
              con el consentimiento de estos y se compromete a trasladarle la información contenida en esta cláusula,
              eximiendo a HARUNNA INVESTMENTS de cualquier responsabilidad en este sentido.
            </li>
          </ul>
        </p>
        <h3>Legitimación – ¿Cuál es la legitimación para el tratamiento de tus datos? </h3>
        <p>
          El tratamiento de datos cuyo fin es el envío de boletines periódicos (newslettering) sobre servicios, eventos
          y noticias relacionadas con nuestra actividad profesional se basa en el consentimiento del interesado,
          solicitado expresamente para llevar a cabo dichos tratamientos, de acuerdo con la normativa vigente. <br />
          Además, la legitimación para el tratamiento de los datos relacionados con ofertas o colaboraciones se basan en
          el consentimiento del usuario que remite sus datos, que puede retirar en cualquier momento, si bien ello puede
          afectar a la posible comunicación de forma fluida y obstrucción de procesos que desea realizar.
          <br /> Por último, los datos se podrán utilizar para dar cumplimiento a las obligaciones legales aplicables a
          HARUNNA INVESTMENTS.
        </p>
        <h3>Plazo de Conservación de los Datos – ¿Por cuánto tiempo conservaremos tus datos?</h3>
        <p>
          HARUNNA INVESTMENTS conservará los datos personales de los usuarios únicamente durante el tiempo necesario
          para la realización de las finalidades para las que fueron recogidos, mientras no revoque los consentimientos
          otorgados. Posteriormente, en caso de ser necesario, mantendrá la información bloqueada durante los plazos
          legalmente establecidos.
        </p>
        <h3>Destinatarios ¿A qué destinatarios se comunicarán tus datos?</h3>
        <p>
          Tus datos podrán ser accedidos por aquellos proveedores que prestan servicios a HARUNNA INVESTMENTS, tales
          como servicios de alojamiento, herramientas de marketing y sistemas de contenido u otros profesionales, cuando
          dicha comunicación sea necesaria normativamente, o para la ejecución de los servicios contratados.
        </p>
        <p>
          HARUNNA INVESTMENTS, ha suscrito los correspondientes contratos de encargo de tratamiento con cada uno de los
          proveedores que prestan servicios a HARUNNA INVESTMENTS, con el objetivo de garantizar que dichos proveedores
          tratarán tus datos de conformidad con lo establecido en la legislación vigente.
        </p>
        <p>
          También podrán ser cedidos a las Fuerzas y Cuerpos de Seguridad del Estado en los casos que exista una
          obligación legal.
        </p>
        <p> Bancos y entidades financieras, para el cobro de los servicios. </p>
        <p>
          Administraciones públicas con competencia en los sectores de actividad, cuando así lo establezca la normativa
          vigente.
        </p>
        <h3>Seguridad de la Información – ¿Qué medidas de seguridad implantamos para cuidar sus datos? </h3>
        <p>
          Para proteger las diferentes tipologías de datos reflejados en esta política de privacidad llevará a cabo las
          medidas de seguridad técnicas necesarias para evitar su pérdida, manipulación, difusión o alteración.
          <ul className="lista-privacidad">
            <li>
              Encriptación de las comunicaciones entre el dispositivo del usuario y los servidores de HARUNNA
              INVESTMENTS.
            </li>
            <li>Encriptación de la información en los propios servidores de HARUNNA INVESTMENTS.</li>
            <li>Otras medidas que eviten el acceso a los datos del usuario por parte de terceros.</li>
            <li>
              En aquellos casos en los que HARUNNA INVESTMENTS cuente con prestadores de servicio para el mantenimiento
              de la plataforma que se encuentren fuera de la Unión Europea, estas trasferencias internacionales se hayan
              regularizadas atendiendo al compromiso de HARUNNA INVESTMENTS con la protección, integridad y seguridad de
              los datos personales de los usuarios.
            </li>
          </ul>
        </p>
        <h3> Derechos – ¿Cuáles son tus derechos cuando nos facilitas tus datos y cómo puedes ejercerlos?</h3>
        <p>
          Tienes derecho a obtener confirmación sobre si en HARUNNA INVESTMENTS estamos tratando datos personales que te
          conciernan, o no
        </p>
        <p>
          Asimismo, tienes derecho a acceder a tus datos personales, así como a solicitar la rectificación de los datos
          inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios
          para los fines que fueron recogidos.
        </p>
        <p>
          En determinadas circunstancias, podrás solicitar la limitación del tratamiento de tus datos, en cuyo caso
          únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.
        </p>
        <p>
          En determinadas circunstancias y por motivos relacionados con tu situación particular, podrás oponerte al
          tratamiento de tus datos. HARUNNA INVESTMENTS dejará de tratar los datos, salvo por motivos legítimos
          imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
        </p>
        <p>
          Asimismo, puedes ejercer el derecho a la portabilidad de los datos, así como retirar los consentimientos
          facilitados en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento
          previo a su retirada.
        </p>
        <p> Si deseas hacer uso de cualquiera de tus derechos puede dirigirse a info@harunnainvestments.com.</p>
        <p>
          Por último, te informamos que puedes dirigirte ante la Agencia Española de Protección de Datos y demás
          organismos públicos competentes para cualquier reclamación derivada del tratamiento de tus datos personales.
        </p>
        <h3>Modificación de la política de privacidad HARUNNA INVESTMENTS</h3>
        <p>
          Podrá modificar la presente Política de Privacidad en cualquier momento, siendo publicadas las sucesivas
          versiones en el Sitio Web. En cualquier caso, HARUNNA INVESTMENTS comunicará con previo aviso las
          modificaciones de la presente política que afecten a los usuarios a fin de que puedan aceptar las mismas.
        </p>
        <p>
          La presente Política de Privacidad se encuentra actualizada a fecha 27/12/2018 HARUNNA INVESTMENTS (España).
        </p>
        <p> Reservados todos los derechos.</p>
      </Container>

      <Row>
        <Col></Col>
      </Row>
      <BannerInfo />
      <Footer />
    </div>
  );
}

export default GuestPrivacidad;
