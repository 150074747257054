import React from "react";
import "./GuestMain.css";

import GuestNavbar from "./guest-navbar/GuestNavbar";
import GuestCarousel from "./guest-carousel/GuestCarousel";
import GuestHighlighted from "./guest-highlighted/GuestHighlighted";
import Footer from "./footer/footer";
import BannerInfo from "./guest-banner-index/GuestBannerIndex";

function GuestMain() {
  return (
    <div>
      <GuestNavbar />
      <GuestCarousel />
      <GuestHighlighted />
      <BannerInfo />
      <Footer />
    </div>
  );
}

export default GuestMain;
