import React, { useState, useEffect } from "react";
import "./GuestDetailFormContact.css";

import { useForm } from "react-hook-form";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

import contactIcon from "../../img/contact-icon.svg";
import correoElectronicoIcon from "../../img/icon/correo-electronico.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import SendEmail from "../../guest-contacto/SendEmail";

const initialState = {
  reference: "",
  replyEmail: "",
  phone: "",
  message: "",
};

function GuestDetailFormContact({ reference }) {
  const [formState, setFormState] = useState(initialState);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmitSuccessful, setFormSubmitSuccessful] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setFormState(() => ({
      ...formState,
      [reference]: reference,
    }));
  }, [reference]);

  function onChange(e) {
    e.persist();
    setFormState(() => ({
      ...formState,
      [e.target.id]: e.target.value,
    }));
  }

  function sendMessage() {
    setFormSubmitted(true);
    SendEmail(formState).then((then) => {
      setFormSubmitSuccessful(true);
    });
    setTimeout(() => {
      setShow(false);
    }, 5000);
  }

  return (
    <Container className="fixed-bottom">
      <Row>
        <Button variant="success" block onClick={handleShow} className="boton-listado">
          <img src={contactIcon} alt="contactIcon-button" /> Enviar
        </Button>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <img src={contactIcon} alt="contactIcon-modal" /> Enviar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formSubmitSuccessful && (
            <Container fluid>
              <Row>
                <Col md={12} className="container-sended-icon">
                  <Image src={correoElectronicoIcon} rounded height="100px" />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="container-sended-text">
                  <h3>Mensaje Enviado</h3>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="container-sended-text">
                  <h5> Se han enviado el mensaje correctamente, nos pondremos en contacto contigo</h5>{" "}
                </Col>
              </Row>
            </Container>
          )}
          {!formSubmitSuccessful && (
            <>
              <h4>¿Alguna duda? Pregúntanos</h4>
              <Form onSubmit={handleSubmit(sendMessage)}>
                <Form.Group>
                  <Form.Control
                    id="message"
                    name="message"
                    as="textarea"
                    placeholder="Hola, estoy interesado en este inmueble ¿Podemos concretar una visita?"
                    rows={3}
                    onChange={onChange}
                    ref={register({
                      required: {
                        value: true,
                        message: "Debes redactar tu consulta",
                      },
                      minLength: {
                        value: 10,
                        message: "Mínimo 10 carácteres",
                      },
                    })}
                  />
                  <span className="text-danger text-small d-block mb-2 span-error-app">
                    {errors?.message?.message && (
                      <p>
                        <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.message?.message}
                      </p>
                    )}
                  </span>
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="@"
                    id="replyEmail"
                    name="replyEmail"
                    onChange={onChange}
                    ref={register({
                      required: {
                        value: true,
                        message: "Debes informar tu correo",
                      },
                      pattern: {
                        value: /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm,
                        message: "El email introducido no es valido",
                      },
                    })}
                  />
                  <span className="text-danger text-small d-block mb-2 span-error-app">
                    {errors?.replyEmail?.message && (
                      <p>
                        <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.replyEmail?.message}
                      </p>
                    )}
                  </span>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Teléfono"
                    id="phone"
                    name="phone"
                    onChange={onChange}
                    ref={register({
                      required: {
                        value: true,
                        message: "Debes informar tu telefono",
                      },
                      pattern: {
                        value: /^[0-9]+$/g,
                        message: "El teléfono introducido no es valido",
                      },
                    })}
                  />
                  <span className="text-danger text-small d-block mb-2 span-error-app">
                    {errors?.phone?.message && (
                      <p>
                        <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.phone?.message}
                      </p>
                    )}
                  </span>
                </Form.Group>
                <Button variant="primary" type="submit" block disabled={formSubmitted} className="boton-listado">
                  <img src={contactIcon} alt="contact-icon" />
                  {"  "}Enviar
                </Button>
              </Form>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default GuestDetailFormContact;
