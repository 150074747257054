import React, { useEffect } from "react";
import "./GuestNavbar.css";
import logoBrand from "../../img/harunna-logo-sin-fondo.png";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

function GuestNavbar() {
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute("src", "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit");
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "es",
        includedLanguages: "en,es",
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "google_translate_element",
    );
  };

  function cleanStorage() {
    localStorage.removeItem("currentFormState");
    localStorage.removeItem("currentFilter");
  }
  return (
    <header>
      <Navbar bg="light" expand="lg" sticky="top" className="d-flex flex-md-row justify-content-between">
        <Container fluid className="container-translate">
          <div id="google_translate_element"></div>
        </Container>
      </Navbar>
      <Navbar bg="light" expand="lg" sticky="top" className="d-flex flex-md-row justify-content-between">
        <Container className="d-flex flex-md-row justify-content-between container-nav">
          <Navbar.Brand href="/">
            <img src={logoBrand} height="140" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/" onClick={cleanStorage}>
                <div onClick={cleanStorage} className="container-item-nav">
                  <h5 onClick={cleanStorage}>INICIO</h5>
                </div>
              </Nav.Link>
              <Nav.Link href="/search" onClick={cleanStorage}>
                <div className="container-item-nav" onClick={cleanStorage}>
                  <h5 onClick={cleanStorage}>BUSCADOR</h5>
                </div>
              </Nav.Link>
              <Nav.Link href="/contact" onClick={cleanStorage}>
                <div onClick={cleanStorage} className="container-item-nav">
                  <h5 onClick={cleanStorage}>CONTACTO</h5>
                </div>
              </Nav.Link>
              <Nav.Link href="/services" onClick={cleanStorage}>
                <div onClick={cleanStorage} className="container-item-nav">
                  <h5 onClick={cleanStorage}>SERVICIOS</h5>
                </div>
              </Nav.Link>
              <Nav.Link href="/about" onClick={cleanStorage}>
                <div onClick={cleanStorage} className="container-item-nav">
                  <h5 onClick={cleanStorage}>SOBRE NOSOTROS</h5>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default GuestNavbar;
