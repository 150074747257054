import React from "react";

import "./ProgressUploadFiles.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const ProgressUploadFiles = () => {
  return (
    <Container fluid className="container-progress">
      <Container fluid className="container-spinner">
        <Row>
          <Col className="col-spinner">
            <FontAwesomeIcon spin icon={faCircleNotch} pull="left" size="9x" />
          </Col>
        </Row>
        <Row>
          <Col className="col-spinner">
            {/* <Alert variant="info">Estamos subiendo las imagenes y creando los registros</Alert> */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ProgressUploadFiles;
