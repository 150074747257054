import React from "react";
import "./Page404.css";

import GuestNavbar from "../guest-main/guest-navbar/GuestNavbar";
import Footer from "../guest-main/footer/footer";
import BannerInfo from "../guest-main/guest-banner-index/GuestBannerIndex";

function Page404() {
  return (
    <div>
      <GuestNavbar />
      <div className="image-404"></div>
      <BannerInfo />
      <Footer />
    </div>
  );
}

export default Page404;
