import React from "react";
import "./footer-copyright.css";

import Navbar from "react-bootstrap/Navbar";

function FooterCopyright() {
  function currentYear() {
    return new Date().getFullYear();
  }
  return (
    <Navbar style={{ height: "20px" }} className="justify-content-center" color="light">
      <Navbar.Brand className="text-center mt-4 mb-4">© {currentYear()} Harunna Investment</Navbar.Brand>
    </Navbar>
  );
}

export default FooterCopyright;
