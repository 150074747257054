import React, { useState } from "react";

import "./GuestCarousel.css";

import architecture3d from "../../img/architecture-3d-1280.jpg";
import keyHome from "../../img/key-home-12802.jpg";
import carrousel3 from "../../img/carrousel-3.jpg";

import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";

function GuestCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="carousel">
      <Carousel.Item className="carousel-item">
        <Card className="text-center">
          <img className="card-img-top" src={architecture3d} alt="Home1" />
          <Card.Body>
            <Card.Text as="h3">
              <strong>
                <i>HOMEFULLNESS</i>, una nueva palabra para definir el <i>PLACER</i> de llegar a <i>CASA</i>
              </strong>
            </Card.Text>
          </Card.Body>
        </Card>
      </Carousel.Item>
      <Carousel.Item className="carousel-item">
        <Card className="text-center">
          <img className="card-img-top" src={keyHome} alt="Home2" />
          <Card.Body>
            <Card.Text as="h3">
              <strong>
                <i>ENAMÓRATE</i>
              </strong>
            </Card.Text>
          </Card.Body>
        </Card>
      </Carousel.Item>
      <Carousel.Item className="carousel-item">
        <Card className="text-center">
          <img className="card-img-top" src={carrousel3} alt="Home3" />
          <Card.Body>
            <Card.Text as="h3">
              <strong>
                <i>"Haz de tu Vida un Sueño, y de tu Sueño una Realidad"</i>
              </strong>
            </Card.Text>
            <footer className="blockquote-footer">
              <cite title="Source Title">El Príncipito</cite>
            </footer>
          </Card.Body>
        </Card>
      </Carousel.Item>{" "}
      {/* <Carousel.Item className="carousel-item"> */}
      {/* <img className="d-block w-auto" src={home1} alt="First slide" />
        <Carousel.Caption>
          <h3>
            <strong>
              <i>HOMEFULLNESS</i>
            </strong>
            , una nueva palabra para definir el{" "}
            <strong>
              <i>PLACER</i>
            </strong>{" "}
            de llegar a{" "}
            <strong>
              <i>CASA</i>
            </strong>
          </h3>
          <h4>
            <i>
              La plasticidad del lenguaje es tal que nos permite inventar nuevos términos para definir emociones que
              antes no estaban representadas.
            </i>
          </h4>
        </Carousel.Caption>

      {/* <Carousel.Item className="carousel-item">
        <img className="d-block w-auto" src={carrousel4} alt="Second slide" />
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="carousel-item">
        <img className="d-block w-auto" src={carrousel3} alt="Third slide" />
        <Carousel.Caption>
          <h3>
            <strong>
              <i>HOMEFULLNESS</i>
            </strong>
            , una nueva palabra para definir el{" "}
            <strong>
              <i>PLACER</i>
            </strong>{" "}
            de llegar a{" "}
            <strong>
              <i>CASA</i>
            </strong>
          </h3>
          <h4>
            <i>
              La plasticidad del lenguaje es tal que nos permite inventar nuevos términos para definir emociones que
              antes no estaban representadas.
            </i>
          </h4>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  );
}

export default GuestCarousel;
