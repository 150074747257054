import React from "react";
import "./GestServicesMain.css";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

// import Jumbotron from "react-bootstrap/Jumbotron";

import GuestNavbar from "../guest-main/guest-navbar/GuestNavbar";
import Footer from "../guest-main/footer/footer";
import BannerInfo from "../guest-main/guest-banner-index/GuestBannerIndex";
import imagenAnalisis from "../img/servicios_analisis.svg";
import imagenCompraVenta from "../img/servicios_compraventa.svg";
import imagenGestionHipotecaria from "../img/servicios_gestionhipotecaria.svg";
import imagenGestiones from "../img/servicios_gestiones.svg";
import imagenTasaciones from "../img/servicios_tasaciones.svg";
import imagenCasasCarta from "../img/servicios_casascarta.svg";
import Image from "react-bootstrap/Image";

function GestServicesMain() {
  return (
    <div>
      <GuestNavbar />
      <div className="image"></div>
      <Container>
        <Row className="fila-services">
          <Col sm={4}>
            <Card>
              <Card.Header>
                <strong>COMPRAVENTA DE INMUEBLES</strong>
              </Card.Header>
              <Container fluid className="container-imagen-services">
                <Image src={imagenCompraVenta} rounded />
              </Container>

              <Card.Body className="body-services text-center">
                <Card.Text>
                  Te ayudamos y orientamos en el proceso de compra. Buscamos la vivienda perfecta para ti,
                  representándote y defendiendo tus intereses.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-muted">
                <a href="/contact">
                  <Button variant="outline-info">MÁS INFORMACIÓN</Button>
                </a>{" "}
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={4}>
            <Card>
              <Card.Header>
                <strong>GESTIÓN HIPOTECARIA</strong>
              </Card.Header>
              <Container fluid className="container-imagen-services">
                <Image src={imagenGestionHipotecaria} rounded />
              </Container>

              <Card.Body className="body-services text-center">
                <Card.Text>
                  Te ofrecemos entidades financieras que cuenten con hipotecas que te permitan obtener un 100% de
                  financiación en la compra de tu vivienda.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-muted">
                <a href="/contact">
                  <Button variant="outline-info">MÁS INFORMACIÓN</Button>
                </a>{" "}
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={4}>
            <Card>
              <Card.Header>
                <strong>GESTIONES BUROCRÁTICAS</strong>
              </Card.Header>
              <Container fluid className="container-imagen-services">
                <Image src={imagenGestiones} rounded />
              </Container>

              <Card.Body className="body-services text-center">
                <Card.Text>
                  Nos encargamos de toda la gestión de documentos desde el principio hasta el final de la compra del
                  inmueble. Entre otros se incluyen: IBI, comunidad, CE, etc.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-muted">
                <a href="/contact">
                  <Button variant="outline-info">MÁS INFORMACIÓN</Button>
                </a>{" "}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row className="fila-services">
          <Col sm={4}>
            <Card>
              <Card.Header>
                <strong>TASACIONES</strong>
              </Card.Header>
              <Container fluid className="container-imagen-services">
                <Image src={imagenTasaciones} rounded />
              </Container>

              <Card.Body className="body-services text-center">
                <Card.Text>
                  Te asesoramos en el proceso de tasación para determinar el valor del inmueble cuando se pide una
                  hipoteca.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-muted">
                <a href="/contact">
                  <Button variant="outline-info">MÁS INFORMACIÓN</Button>
                </a>{" "}
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={4}>
            <Card>
              <Card.Header>
                <strong>CASAS A LA CARTA</strong>
              </Card.Header>
              <Container fluid className="container-imagen-services">
                <Image src={imagenCasasCarta} rounded />
              </Container>

              <Card.Body className="body-services text-center">
                <Card.Text>
                  Ofrecemos atención personalizada a todos los clientes que deciden ponerse en nuestras manos.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-muted">
                <a href="/contact">
                  <Button variant="outline-info">MÁS INFORMACIÓN</Button>
                </a>{" "}
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={4}>
            <Card>
              <Card.Header className="letra-services">
                <strong>ANÁLISIS DEL MERCADO INMOBILIARIO</strong>
              </Card.Header>
              <Container fluid className="container-imagen-services">
                <Image src={imagenAnalisis} rounded />
              </Container>

              <Card.Body className="body-services text-center">
                <Card.Text>
                  Análisis de la evolución de la oferta y la demanda para asesorarte en la compra o venta de tu
                  vivienda.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-muted">
                <a href="/contact">
                  <Button variant="outline-info">MÁS INFORMACIÓN</Button>
                </a>{" "}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <BannerInfo />
      <Footer />
    </div>
  );
}

export default GestServicesMain;
