import React, { useState, useEffect } from "react";
import "./GuestDetail.css";

import { useRouteMatch } from "react-router-dom";

import { useForm } from "react-hook-form";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Image from "react-bootstrap/Image";

import { houseByReference } from "../graphql/custom-queries";
import { Storage, API } from "aws-amplify";

import GuestNavbar from "../guest-main/guest-navbar/GuestNavbar";
import Footer from "../guest-main/footer/footer";
import BannerInfo from "../guest-main/guest-banner-index/GuestBannerIndex";
import ModalContactForm from "./guest-detail-form-contact/GuestDetailFormContact";

import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import correoElectronicoIcon from "../img/icon/correo-electronico.svg";

import SendEmail from "../guest-contacto/SendEmail";

import contactIcon from "../img/contact-icon.svg";
import playRoundedIcon from "../img/play-reounded-icon.png";
import certEnergyA from "../img/icon/certificacion-a.png";
import certEnergyB from "../img/icon/certificacion-b.png";
import certEnergyC from "../img/icon/certificacion-c.png";
import certEnergyD from "../img/icon/certificacion-d.png";
import certEnergyE from "../img/icon/certificacion-e.png";
import certEnergyF from "../img/icon/certificacion-f.png";
import certEnergyG from "../img/icon/certificacion-g.png";

const initialState = {
  reference: "",
  replyEmail: "",
  phone: "",
  message: "",
};

function GuestDetail() {
  const [formState, setFormState] = useState(initialState);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmitSuccessful, setFormSubmitSuccessful] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  const [estate, setEstate] = useState();
  const [indexVideos, setIndexVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [urlMap, setUrlMap] = useState("");
  const [energyCert, setEnergyCert] = useState(null);
  const [energyCertString, setEnergyCertString] = useState(null);
  const match = useRouteMatch("/details/:reference");

  useEffect(() => {
    loadEstate();
  }, []);

  async function loadEstate() {
    if (match.params.reference) {
      let estate = await API.graphql({ query: houseByReference, variables: { reference: match.params.reference } });
      if (estate?.data?.houseByReference?.items[0]) {
        let response = estate.data.houseByReference.items[0];
        setFormState(() => ({
          ...formState,
          ["reference"]: response.reference,
        }));
        setEstate(response);
        if (response.showAddress) {
          setUrlMap(
            "https://www.google.com/maps/embed/v1/place?key=AIzaSyDQId-u6z5Oau0xdAzHS9zQ5hsg4-_O3so&q=" +
              response.address +
              "," +
              response.zone +
              "," +
              response.city,
          );
        } else {
          setUrlMap(
            "https://www.google.com/maps/embed/v1/place?key=AIzaSyDQId-u6z5Oau0xdAzHS9zQ5hsg4-_O3so&q=" +
              response.zone +
              "," +
              response.city,
          );
        }
        loadEnergyCert(response.energyCertification);
        loadImages(response.media);
      }
    }
  }

  async function loadImages(images) {
    let urlImages = [];
    let urlVideos = [];
    let allResolved = Promise.all(
      await images.map(async (image) => {
        let url = await Storage.get(image, {
          level: "public",
        });
        if (image.startsWith("video-")) {
          urlVideos.push({
            original: playRoundedIcon,
            embedUrl: url,
            showVideo: true,
            renderItem: renderVideo.bind(this),
          });
        } else {
          urlImages.push({
            original: url,
            thumbnail: "",
          });
        }
      }),
    );

    allResolved.then((then) => {
      let mediaList = urlImages.concat(urlVideos);
      searchIndexVideos(mediaList);
      setImages(mediaList);
    });
  }

  function loadEnergyCert(energyCertification) {
    switch (energyCertification) {
      case "A":
        setEnergyCert(certEnergyA);
        break;
      case "B":
        setEnergyCert(certEnergyB);
        break;
      case "C":
        setEnergyCert(certEnergyC);
        break;
      case "D":
        setEnergyCert(certEnergyD);
        break;
      case "E":
        setEnergyCert(certEnergyE);
        break;
      case "F":
        setEnergyCert(certEnergyF);
        break;
      case "G":
        setEnergyCert(certEnergyG);
        break;
      case "not-available":
        setEnergyCertString("Aún no disponible");
        break;
      case "in-process":
        setEnergyCertString("En trámite");
        break;
      case "excent":
        setEnergyCertString("Inmueble exento");
        break;
      default:
        break;
    }
  }

  function searchIndexVideos(media) {
    let listIndexVideos = [];
    media.map((item, index) => {
      if (item.embedUrl) listIndexVideos.push(index);
    });
    if (listIndexVideos.length > 0) {
      setIndexVideos(listIndexVideos);
    }
  }

  function addCommas(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2 + ",00";
  }

  function onSlide(index) {
    let isVideoSlide = indexVideos.filter((item) => item === index);
    let showVideo = isVideoSlide.length > 0 ? true : false;
    let imagesList = [];
    images.map((item) => {
      if (item.embedUrl) item.showVideo = showVideo;
      imagesList.push(item);
    });
    setImages(imagesList);
  }

  function onChange(e) {
    e.persist();
    setFormState(() => ({
      ...formState,
      [e.target.id]: e.target.value,
    }));
  }

  function sendMessage() {
    setFormSubmitted(true);
    SendEmail(formState).then((then) => {
      setTimeout(() => {
        setFormSubmitSuccessful(true);
      }, 2000);
    });
  }

  const renderVideo = function (item) {
    return (
      <div className="container-video">
        <ReactPlayer url={item.embedUrl} playing={item.showVideo} controls light={playRoundedIcon} />
      </div>
    );
  };

  return (
    <div>
      <GuestNavbar />
      {estate && (
        <Container fluid>
          <Container fluid className="container-images">
            {images.length > 0 && (
              <ImageGallery lazyLoad={true} showThumbnails={false} items={images} onSlide={onSlide} />
            )}
            {images.length === 0 && (
              <Container className="spinner-images">
                <FontAwesomeIcon spin icon={faCircleNotch} pull="left" size="9x" />
              </Container>
            )}
          </Container>
          <Jumbotron fluid>
            <Container className="details-info">
              <Row>
                <Col md="8">
                  <Button href="/search" variant="outline-primary" className="boton-listado">
                    {"« "} Volver al listado
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md="8" className="info-estados">
                  <p className="reference">#{estate.reference}</p>
                  <h3>
                    {estate.houseType}: {estate.title}
                  </h3>
                  <h5>
                    {estate.zone}, {estate.city}
                  </h5>
                  {estate.showAddress && (
                    <p>
                      {estate.address} {estate.block} {estate.floor} {estate.door}
                    </p>
                  )}
                  <h5>Precio: {addCommas(estate.price)} €</h5>
                  {estate.sale && (
                    <Button variant="success" disabled>
                      EN VENTA
                    </Button>
                  )}{" "}
                  {estate.rentSaleOrRent && (
                    <Button variant="success" disabled>
                      ALQUILER CON OPCIÓN A COMPRA
                    </Button>
                  )}{" "}
                  {estate.reserved && (
                    <Button variant="warning" disabled>
                      RESERVADO
                    </Button>
                  )}{" "}
                  {estate.rent && (
                    <Button variant="info" disabled>
                      ALQUILER
                    </Button>
                  )}{" "}
                  {estate.sold && (
                    <Button variant="danger" disabled>
                      VENDIDO
                    </Button>
                  )}{" "}
                  {estate.rented && (
                    <Button variant="danger" disabled>
                      ALQUILADA
                    </Button>
                  )}{" "}
                  <Button variant="info" disabled>
                    {estate.houseStatus}
                  </Button>
                </Col>
                <Col md="4" className="form-contact">
                  {formSubmitSuccessful && (
                    <Container fluid>
                      <Row>
                        <Col md={12} className="container-sended-icon">
                          <Image src={correoElectronicoIcon} rounded height="100px" />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="container-sended-text">
                          <h3>Mensaje Enviado</h3>
                        </Col>
                      </Row>
                    </Container>
                  )}
                  {!formSubmitSuccessful && (
                    <Container className="form-contact-container">
                      <h4>¿Alguna duda? Pregúntanos</h4>
                      <Form onSubmit={handleSubmit(sendMessage)}>
                        <Form.Group>
                          <Form.Control
                            id="message"
                            name="message"
                            as="textarea"
                            placeholder="Hola, estoy interesado en este inmueble ¿Podemos concretar una visita?"
                            rows={3}
                            onChange={onChange}
                            ref={register({
                              required: {
                                value: true,
                                message: "Debes redactar tu consulta",
                              },
                              minLength: {
                                value: 10,
                                message: "Mínimo 10 carácteres",
                              },
                            })}
                          />
                          <span className="text-danger text-small d-block mb-2 span-error-app">
                            {errors?.message?.message && (
                              <p>
                                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.message?.message}
                              </p>
                            )}
                          </span>
                        </Form.Group>

                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="@"
                            id="replyEmail"
                            name="replyEmail"
                            onChange={onChange}
                            ref={register({
                              required: {
                                value: true,
                                message: "Debes informar tu correo",
                              },
                              pattern: {
                                value: /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm,
                                message: "El email introducido no es valido",
                              },
                            })}
                          />
                          <span className="text-danger text-small d-block mb-2 span-error-app">
                            {errors?.replyEmail?.message && (
                              <p>
                                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.replyEmail?.message}
                              </p>
                            )}
                          </span>
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Teléfono"
                            id="phone"
                            name="phone"
                            onChange={onChange}
                            ref={register({
                              required: {
                                value: true,
                                message: "Debes informar tu telefono",
                              },
                              pattern: {
                                value: /^[0-9]+$/g,
                                message: "El teléfono introducido no es valido",
                              },
                            })}
                          />
                          <span className="text-danger text-small d-block mb-2 span-error-app">
                            {errors?.phone?.message && (
                              <p>
                                <FontAwesomeIcon icon={faExclamationCircle} color="red" /> {errors?.phone?.message}
                              </p>
                            )}
                          </span>
                        </Form.Group>
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={formSubmitted}
                          block
                          className="boton-listado"
                        >
                          <img src={contactIcon} alt="contact-icon" />
                          {"  "}Enviar
                        </Button>
                      </Form>
                    </Container>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="8" className="first-info">
                  <div>
                    <h5>
                      {estate.surface} m<sup>2</sup>
                    </h5>
                  </div>
                  <div>
                    <h5>{estate.bedrooms} Habitaciones</h5>
                  </div>
                  <div>
                    <h5>{estate.bathrooms} Baños</h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <p className="paragraph-highlighted-text">
                    {estate.highlightedText.split("\n").map(function (item, index) {
                      return (
                        <span key={index}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                  </p>
                  <p>
                    {estate.description.split("\n").map(function (item, index) {
                      return (
                        <span key={index}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <Container fluid>
                    <Row>
                      <Col md="12">
                        <h5>Características</h5>
                      </Col>
                      <Col md="6">
                        <ul>
                          {estate.airConditioning && <li>Aire acondicionado</li>}
                          {estate.balcony && <li>Balcon</li>}
                          {estate.builtClosets && <li>Armarios empotrados</li>}
                          {estate.lift && <li>Ascensor</li>}
                          {estate.parking && <li>Parking</li>}
                          {estate.pool && <li>Piscina</li>}
                          {estate.storageRoom && <li>Trastero</li>}
                          {estate.terrace && <li>Terraza</li>}
                          {estate.yard && <li>Patio</li>}
                          {estate.energyCertification && (
                            <li>
                              Certificación energética:{"  "}
                              {energyCert && (
                                <span>
                                  <img height="25px" src={energyCert} alt="energy-cert-icon" />
                                </span>
                              )}
                              {energyCert == null && energyCertString && energyCertString}
                              {energyCert == null && energyCertString == null && estate.energyCertification}
                            </li>
                          )}
                        </ul>
                      </Col>
                      <Col md="6">
                        <ul>
                          {estate.builtYear && <li>Año de construcción: {estate.builtYear}</li>}
                          {estate.surfacePlot && (
                            <li>
                              Superficie parcela: {estate.surfacePlot} m<sup>2</sup>
                            </li>
                          )}
                          {estate.usefulSurface && (
                            <li>
                              Superficie útil: {estate.usefulSurface} m<sup>2</sup>
                            </li>
                          )}
                        </ul>
                        <h5>Calefacción</h5>
                        <ul>
                          {estate.centralHeating && <li>Centralizada</li>}
                          {estate.individualHeating && <li>Individual</li>}
                          {estate.heatingNotAvailable && <li>No disponible</li>}
                        </ul>
                        {(estate.northOrientation ||
                          estate.eastOrientation ||
                          estate.southOrientation ||
                          estate.westOrientation) && (
                          <>
                            <h5>Orientación</h5>
                            <ul>
                              <li>
                                <p>
                                  {estate.northOrientation && "Norte "}
                                  {estate.eastOrientation && "Este "}
                                  {estate.southOrientation && "Sur "}
                                  {estate.westOrientation && "Oeste "}
                                </p>
                              </li>
                            </ul>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row className="row-map">
                <Col md="6">
                  <iframe
                    title="google-maps"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    style={{ border: 0 }}
                    src={urlMap}
                    allowFullScreen
                  ></iframe>
                </Col>
              </Row>
            </Container>
          </Jumbotron>
          <ModalContactForm reference={estate.reference} />
        </Container>
      )}
      <BannerInfo />
      <Footer />
    </div>
  );
}

export default GuestDetail;
